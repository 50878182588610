// 系统类Api接口
import { get, post } from '@/util/request';

/**
 * 用户名登录接口
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const pasLogin = (p = {}) => post('/login', p);

/**
 * 手机登录接口
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const codeLogin = (p = {}) => post('/login/code', p);

/**
 * 注册用户
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const registerUsers = (p = {}) => post('/register', p);

/**
 * 退出系统
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const logoutSys = (p = {}) => post('/user/logout', p);

/**
 * 获取验证码
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const sendCode = (p = {}) => post('/user/sendcode/email', p);

/**
 * 获取用户信息
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const getUserDetail = (p = {}) => get('/user/detail', p);

/**
 * 更改密码
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const editPas = (p = {}) => post('/user/password/change', p);

/**
 * 基本资料修改
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const editUserInfo = (p = {}) => post('/user/setup', p);
