import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPersist from 'pinia-plugin-persist';
import Antd, {
    Breadcrumb,
    Button,
    Carousel,
    Cascader,
    Checkbox,
    Col,
    DatePicker,
    Descriptions,
    Divider,
    Drawer,
    Dropdown,
    Form,
    Input,
    InputNumber,
    Menu,
    message,
    Modal,
    Pagination,
    Popconfirm,
    Popover,
    Radio,
    Row,
    Select,
    Spin,
    Steps,
    Switch,
    Table,
    Tabs,
    Transfer,
    Tree,
    Upload,
} from 'ant-design-vue';
import { DownOutlined, InboxOutlined, UpOutlined } from '@ant-design/icons-vue';

import globalComponent from '@/components/index';

import router from '@/router';
import App from '@/App.vue';
import 'ant-design-vue/dist/antd.css';
import '@/assets/style/common.less';
import { imgLazy } from '@/util/directives';

const app = createApp(App);
const pinia = createPinia();

pinia.use(piniaPersist);

// antd

app.component('DownOutlined', DownOutlined);
app.component('UpOutlined', UpOutlined);
app.component('InboxOutlined', InboxOutlined);

app.component('Input', Input);
app.component('Textarea', Input.TextArea);
app.component('Select', Select);
app.component('Option', Select.Option);
app.component('InputNumber', InputNumber);

app.use(Checkbox);
app.use(Radio);
app.use(Switch);

app.use(Button);
app.use(Table);

app.use(Tree);
app.use(Row);
app.use(Col);
app.use(Pagination);
app.use(Input);
app.use(Select);
app.use(Steps);
app.use(Breadcrumb);
app.use(Carousel);
app.use(Upload);
app.use(Tabs);
app.use(Form);
app.use(Transfer);
app.use(Popconfirm);
app.use(Divider);
app.use(Popover);
app.use(Drawer);
app.use(Dropdown);

app.use(Spin);
app.use(Cascader);
app.use(Descriptions);
app.use(Menu);
app.use(Modal);
app.use(DatePicker);

app.config.globalProperties.$message = message;

app.use(pinia);
app.use(router);
app.use(Antd);
for (const componentItme in globalComponent) {
    app.component(componentItme, globalComponent[componentItme]);
}

app.directive('img-lazy', imgLazy);

app.mount('#app');
