<template>
    <Header />
    <div class="base-container">
        <div class="page-container" ref="scrollDom">
            <div class="category">
                <div class="category_list set_vertical_center">
                    <SwiperStyleCom :symbols="styleList" @toggleTag="changeStyleList"></SwiperStyleCom>
                </div>
                <div class="search">
                    <a-input v-model:value="keyword" placeholder="搜索关键词" @keyup.enter="searchData">
                        <template #suffix>
                            <search-outlined style="font-size: 14px; color: #30e38d" />
                        </template>
                    </a-input>
                </div>
            </div>
            <div class="image_list">
                <a-list v-if="generationsList.length" :grid="{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 5, xxxl: 6 }" :data-source="generationsList">
                    <template #renderItem="{ item }">
                        <a-list-item>
                            <ImageItem :itemData="item"></ImageItem>
                        </a-list-item>
                    </template>
                </a-list>
            </div>
            <div class="image_list_pagination">
                <a-pagination
                    :current="currentPage"
                    @change="getGenerationsListData"
                    @showSizeChange="getGenerationsListData"
                    :page-size="perPage"
                    :total="totalCount"
                    :show-total="total => `合计 ${total} 条记录`"
                    :hideOnSinglePage="true"
                />
            </div>
            <Footer class="footerCon" :class="{ fixedBottom: !generationsList.length }"></Footer>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { SearchOutlined } from '@ant-design/icons-vue';
import { onBeforeMount, ref, watch } from 'vue';
import { useScroll } from '@vueuse/core';
import ImageItem from '@/components/ImageItem/index.vue';
import { ApiRes } from '@/types/data';
import { getGenerationsList } from '@/api/creative';
import { pubilcGenerationsListRes, pubilcGenerationsPageRes } from '@/types/creative';
import { styleListRes, stylesItemRes } from '@/types/home';
import { getStylesList } from '@/api/home';
import Footer from '@/components/Footer/Footer.vue';
import SwiperStyleCom from '@/views/creativeSquare/components/swiperStyleCom.vue';
import Header from '../../layout/modules/Header.vue';

// 获取风格列表
const currentStyle = ref();
const pageContainer = ref();
const styleList = ref<stylesItemRes[]>([{ id: 0, name_cn: '全部', name_en: '' }]);
const getStylesListData = async () => {
    const res = (await getStylesList()) as ApiRes<styleListRes[]>;
    if (!res.code) {
        res.data.forEach((i) => {
            styleList.value.push(...i.styles);
        });
        currentStyle.value = styleList.value[0].name_en;
    }
};

const keyword = ref();
const currentPage = ref(1);
const perPage = ref(20);
const totalCount = ref(1);
const generationsList = ref<pubilcGenerationsListRes[]>([]);
const getGenerationsListData = async (thisPage, pageSize) => {
    currentPage.value = thisPage;
    perPage.value = pageSize;
    const params = {
        page: thisPage,
        limit: pageSize,
        keyword: keyword.value,
        style: currentStyle.value ? currentStyle.value : null,
    };
    const res = (await getGenerationsList(params)) as ApiRes<pubilcGenerationsPageRes>;
    if (!res.code) {
        generationsList.value = res.data.data;
        totalCount.value = res.data.total;
        pageContainer.value = document.querySelector('.page-container');
        pageContainer.value.scrollTop = 0;
    }
};

const clearHistoryData = () => {
    generationsList.value = [];
    currentPage.value = 1;
};

const changeStyleList = (id) => {
    keyword.value = '';
    clearHistoryData();
    currentStyle.value = id;
    getGenerationsListData(1, perPage.value);
};

const searchData = () => {
    currentStyle.value = null;
    clearHistoryData();
    getGenerationsListData(1, perPage.value);
};

const scrollDom = ref<HTMLElement | null>(null);
const { arrivedState } = useScroll(scrollDom);
onBeforeMount(async () => {
    await getStylesListData();
    await getGenerationsListData(1, perPage.value);
});
</script>

<style lang="less" scoped>
.page-container {
    overflow: scroll;
    padding-bottom: 80px;

    .category {
        display: flex;
        justify-content: space-between;
        margin-bottom: 46px;

        .search {
            :deep(.ant-input-affix-wrapper) {
                width: 270px;
                height: 32px;
                background: rgba(48, 227, 141, 0.05);
                border-radius: 16px;
                border: 1px solid rgba(48, 227, 141, 0.1);
            }

            :deep(.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover) {
                border: 1px solid #30e38d;
            }
        }

        .category_list_item {
            width: 110px;
            height: 32px;
            background: rgba(48, 227, 141, 0.05);
            border-radius: 4px;
            border: 1px solid rgba(48, 227, 141, 0.1);
            cursor: pointer;
            margin-right: 16px;
            white-space: nowrap;

            &:hover {
                border-color: rgba(48, 227, 141, 0.3);
            }
        }

        .active {
            border-color: #30e38d;

            &:hover {
                border-color: #30e38d;
            }
        }
    }

    .image_list {
        //max-width: 1440px;
        margin: 0 auto;

        .image_list_item {
            margin-right: 24px;
            margin-bottom: 24px;
        }

        :deep(.ant-row) {
            & > div {
                width: 326px;
            }
        }

        :deep(.ant-col) {
            display: flex;
            justify-content: center;
            margin-bottom: 24px;
            width: 326px;
        }

        :deep(.ant-spin-container) {
            background-color: #151a24 !important;
        }
    }
}

.loading-data {
    width: 100%;
    height: 80px;
    color: #a2a5a8;
}

:deep(.ant-empty-normal) {
    color: #fff;
}

.set_vertical_center {
    margin-right: 20px;
}
.ant-pagination {
    color: #fff;
}
.image_list_pagination {
    text-align: center;
}
.blur-image {
    filter: blur(5px) brightness(0.5);
}
</style>
