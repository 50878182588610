<template>
    <div>
        <h-modal :width="604" :maskClosable="false" :afterClose="cancel" v-model:visible="visible" :title="!toPay ? 'VIP计划' : '   '">
            <div v-show="!toPay" class="main_box">
                <div class="pay_list set_vertical_center">
                    <div class="pay_list_item" v-for="i in configList" :key="i.id" :class="{ active: currentItem === i.id }" @click="currentItem = i.id">
                        <div>{{ i.point }}&nbsp;媚值</div>
                        <div class="dollar">
                            <span>{{ i.current_amount }}</span>&nbsp;{{ i.currency }}
                        </div>
                        <div class="history_dollar">原价：{{ i.origin_amount }}</div>
                    </div>
                </div>
                <div class="pay_description">
                    <div class="pay_description_title set_vertical_center">
                        <div class="title set_vertical_center">
                            <div class="title_icon">
                                <span></span>
                                <span></span>
                            </div>
                            <div class="title_value">媚值说明</div>
                        </div>
                    </div>
                    <div class="pay_description_text" v-html="description"></div>
                </div>

                <div class="pay_channel_select">
                    <a-space>
                        <a-button :type="payWxType" @click="changeChannelWx" class="pay_channel_select_button">
                            <template #icon>
                                <WechatOutlined />
                            </template>
                            微信支付
                        </a-button>
                        <a-button :type="payCryptoUPType" @click="changeChannelCryptoUP" class="pay_channel_select_button">
                            <template #icon>
                                <DollarOutlined />
                            </template>
                            数字货币支付
                        </a-button>
                    </a-space>    
                </div>

                <div style="text-align: center">
                    <HButton class="pay_btn" @click="submit">升级为VIP</HButton>
                </div>
            </div>
            <div v-show="toPay" class="pay_box">
                <div class="pay_category">媚值：{{ qrCodeData?.point }}</div>
                <a-divider style="border-color: #31373e">
                    <div style="color: #a2a5a8">
                        支付金额：<span style="color: #fff; font-size: 24px">¥{{ qrCodeData?.current_amount }}</span>
                    </div>
                </a-divider>
                <div class="pay_qrCode">
                    <div class="qrCode">
                        <img :src="qrCodeData?.pay_code" alt="" />
                    </div>
                    <div class="code_tag">
                        <div>微信扫码</div>
                    </div>
                </div>
            </div>

        </h-modal>
    </div>
</template>

<script setup lang="ts">
import { ref, h } from 'vue';
import { message } from 'ant-design-vue/es';
import { Modal } from 'ant-design-vue';
import { payConfigListRes, qrCodeRes, cryptoUpRes } from '@/types/home';
import { getPayQrCode, getPayReS, getPlatformWord, getRechargeConfigs, getPayCryptoUP, updatePayReS } from '@/api/home';
import { ApiRes } from '@/types/data';
import { useUserStore } from '@/store';
import router from '@/router';
import { WechatOutlined, DollarOutlined } from '@ant-design/icons-vue';

const visible = ref<boolean>(false);
const toPay = ref(false);
const payChannel = ref('wx');
const payWxType = ref('primary');
const payCryptoUPType = ref('default');

const changeChannelWx = () => {
    payChannel.value = 'wx';
    payWxType.value = 'primary';
    payCryptoUPType.value = 'default';
    getRechargeConfigsData();
}

const changeChannelCryptoUP = () => {
    payChannel.value = 'crypto-up';
    payWxType.value = 'default';
    payCryptoUPType.value = 'primary';
    getRechargeConfigsData();
}

const payThePointsConfirm = async () => {
  Modal.confirm({
    title: 'VIP升级计划',
    content: h('div', {}, [
      h('p', '请在新打开的页面进行充值'),
      h('p', '充值完成后，根据您的情况点击下面按钮'),
    ]),
    okText: '充值完成',
    cancelText: '充值失败',
    onOk() {
        let status = 'Processing';
        checkPayResult().then((res) => {
            status = res;
            if (status == 'Success') {
                message.success('支付成功');
                updateUseUserStore();
                setTimeout(() => {
                    location.reload();
                }, 3000);
            } else {
                message.success('支付暂未到账，请耐心等待...');
                setTimeout(() => {
                    payThePointsConfirm();
                }, 3000);
            }
        });
    },
    onCancel() {
        updatePayResultAsFailed();
    },
  });
};

const updatePayResultAsFailed = async () => {
    await updatePayReS({ pay_id: cryptoUpData.value?.request_id });
};

const checkPayResult = async () => {
    const res = (await getPayReS({ pay_id: cryptoUpData.value?.request_id })) as ApiRes<any>;
    return res.data.status;
};

const updateUseUserStore = async () => {
    await useUserStore().setPoint();
};

const currentItem = ref();

// 获取支付配置
const configList = ref<payConfigListRes[]>();
const getRechargeConfigsData = async () => {
    const res = (await getRechargeConfigs({ channel: payChannel.value })) as ApiRes<payConfigListRes[]>;
    if (!res.code) {
        configList.value = res.data;
        currentItem.value = res.data[res.data.length - 1].id;
    }
};

// 获取支付配置
const description = ref();
const getPlatformWordData = async () => {
    const res = (await getPlatformWord()) as ApiRes<any[]>;
    if (!res.code) {
        description.value = res.data[0].value;
    }
};

const initData = async () => {
    await getRechargeConfigsData();
    await getPlatformWordData();
    visible.value = true;
};

const cancel = () => {
    visible.value = false;
    toPay.value = false;
    clearTimeout(payTimer.value);
};

const qrCodeData = ref<qrCodeRes>();
const cryptoUpData = ref<cryptoUpRes>();
// 获取支付结果
const payTimer = ref();
const getPayResult = async () => {
    const payId = payChannel.value == 'wx' ? qrCodeData.value?.request_id : cryptoUpData.value?.request_id;
    const res = (await getPayReS({ pay_id: payId })) as ApiRes<any>;
    if (!res.code) {
        if (res.data.status === 'Processing') {
            payTimer.value = setTimeout(() => {
                getPayResult();
            }, 2000);
        } else {
            cancel();
            message.success('支付成功');
            Modal.destroyAll();
            await useUserStore().setPoint();
        }
    }
};

const submit = async () => {
    if (payChannel.value == 'wx') {
        const res = (await getPayQrCode({ config_id: currentItem.value })) as ApiRes<qrCodeRes>;
        if (!res.code) {
            toPay.value = true;
            qrCodeData.value = res.data;
            setTimeout(() => {
                getPayResult();
            }, 2000);
        }
    } else {
        const res = (await getPayCryptoUP({ config_id: currentItem.value })) as ApiRes<cryptoUpRes>;
        if (!res.code) {
            cryptoUpData.value = res.data;
            cancel();
            payThePointsConfirm();
            setTimeout(() => {
                getPayResult();
            }, 2000);
            window.open(res.data.pay_url);
        }
    }
};

defineExpose({
    initData,
});
</script>

<style lang="less" scoped>
.main_box {
    .pay_list {
        .pay_list_item {
            width: 180px;
            background: #14171e;
            border-radius: 4px;
            padding: 16px;
            border: 1px solid #14171e;
            cursor: pointer;
            margin-right: 16px;

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                background: rgba(48, 227, 141, 0.05);
                border: 1px solid #30e38d;
            }

            & > div {
                margin-bottom: 8px;
                text-align: center;
            }

            & > div:last-child {
                margin-bottom: 0;
            }

            .dollar {
                color: #30e38d;

                span {
                    font-weight: bold;
                    font-size: 24px;
                }
            }

            .history_dollar {
                text-decoration: line-through;
                color: #a2a5a8;
            }
        }

        .active {
            background: rgba(48, 227, 141, 0.05);
            border: 1px solid #30e38d;
        }
    }

    .pay_description {
        margin-top: 24px;

        .pay_description_title {
            .title {
                margin-bottom: 16px;

                .title_icon {
                    margin-right: 8px;
                    padding-bottom: 4px;

                    span {
                        display: inline-block;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                    }

                    span:first-child {
                        background: #30e38d;
                    }

                    span:last-child {
                        background: #30e3d8;
                    }
                }

                .title_value {
                    font-weight: bold;
                    font-size: 16px;
                }
            }
        }

        .pay_description_text {
            color: #a2a5a8;
        }
    }

    .pay_btn {
        margin: 32px 0 24px;
        width: 325px;
    }
}

.pay_box {
    text-align: center;

    .pay_category {
        font-size: 16px;
        margin-bottom: 32px;
    }

    .pay_qrCode {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .qrCode {
            width: 200px;
            height: 200px;
            background-color: #232a37;
            margin-bottom: 16px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.pay_channel_select {
    text-align: center;
    margin-top: 20px;

    .pay_channel_select_button {
        margin: 10px 30px; 
    }
}
</style>
