<template>
    <a-input autocomplete="off" v-bind="attrs">
        <slot></slot>
    </a-input>
</template>

<script lang="ts" setup>
import { useAttrs } from 'vue';

const attrs = useAttrs();
</script>

<style lang="less" scoped>
.ant-input {
    width: 100%;
    height: 48px;
    background: #161e2b !important;
    border-radius: 8px;
    border: none;
    color: #fff !important;
}
</style>
