<template>
    <Header />
    <div class="base-container">
        <div class="page-container">
            <div class="tool_side">
                <ToolBox class="card" title="描述你喜欢的美媚" height="198">
                    <div class="main_box">
                        <!--                      <div class="word_area">{{ currentWordText }}</div>-->
                        <div class="word_area">
                            <a-textarea v-model:value="currentWordText" placeholder="请描述你喜欢的美媚"></a-textarea>
                        </div>
                        <div class="word_btn set_vertical_center">
                            <div class="word_btn_item set_center auto_change" @click="changeWord('random')" :class="{ word_active: currentWord === 'random' }">
                                <img src="@/assets/images/layout/random.png" alt="" />
                                <span style="margin-left: 8px">随机词条</span>
                            </div>
                            <div class="word_btn_item set_center" :class="{ word_active: currentWord === 'my' }" @click="changeWord('my')">我的词条</div>
                            <div class="word_btn_item set_center" :class="{ word_active: currentWord === 'save' }" @click="changeWord('save')">保存词条</div>
                        </div>
                    </div>
                </ToolBox>

                <ToolBox class="card" height="240" title="选择风格">
                    <div class="style_box">
                        <div class="selected_tag">
                            <div class="style_btn set_center" :class="{ tag_active: currentStyle === i.id }" v-for="i in styleList" :key="i.id" @click="changeStyleList(i)">{{ i.name }}</div>
                        </div>
                        <div class="pic_list">
                            <div
                                class="pic_list_item"
                                v-for="item in styleImageList"
                                :key="item.name_en"
                                :class="{ style_active: currentStyleItemId === item.name_en }"
                                @click="currentStyleItemId = item.name_en"
                            >
                                <img :src="item.image" alt="" />
                                <div class="pic_tag">{{ item.name_cn }}</div>
                            </div>
                        </div>
                    </div>
                </ToolBox>
                <ToolBox class="card" height="161" title="参考图">
                    <div class="upload_box">
                        <div class="show_pic set_center" v-if="currentImageUrl">
                            <img :src="baseImageUrl + currentImageUrl" alt="" />
                            <div class="recover-tool">
                                <div class="recover-tool-btn" @click="currentImageUrl = ''">删除</div>
                                <a-upload
                                    :accept="'.png,.jpeg,.jpg'"
                                    :before-upload="beforeUpload"
                                    :headers="{ authorization: useUserStore().getToken }"
                                    :show-upload-list="false"
                                    :max-count="1"
                                    action="/api/file/upload"
                                    :data="{ type: 'Avatar' }"
                                    name="file"
                                    @change="handleChange"
                                >
                                    <div class="recover-tool-btn">重新上传</div>
                                </a-upload>
                            </div>
                        </div>

                        <a-upload
                            v-show="!currentImageUrl"
                            :accept="'.png,.jpeg,.jpg'"
                            :before-upload="beforeUpload"
                            :headers="{ authorization: useUserStore().getToken }"
                            :show-upload-list="false"
                            :max-count="1"
                            action="/api/file/upload"
                            :data="{ type: 'Reference' }"
                            name="file"
                            :customRequest="customRequest"
                        >
                            <!--                            @change="handleChange"-->
                            <div class="upload_com">
                                <img src="@/assets/images/upload.png" alt="" />
                                <div style="color: #a2a5a8">上传</div>
                            </div>
                        </a-upload>
                    </div>
                </ToolBox>
                <ToolBox class="card" height="120" title="图片尺寸">
                    <div class="pic_box set_vertical_center">
                        <div class="size_btn set_center" :class="{ size_active: currentSize === 512 }" @click="currentSize = 512">512*512</div>
                        <div class="size_btn set_center" :class="{ size_active: currentSize === 1024 }" @click="currentSize = 1024">1024*1024</div>
                    </div>
                </ToolBox>
                <ToolBox class="card" height="120" title="生成张数">
                    <div class="count_box set_vertical_center">
                        <div class="count_btn set_center" :class="{ count_active: currentCount === i }" v-for="i in countList" :key="i" @click="currentCount = i">{{ i }}</div>
                    </div>
                </ToolBox>

                <div class="generate">
                    <h-button @click="onSubmit" :loading="loadingStatus">
                        <span class="generate_text">立即生成 · 本次消耗{{ currentScore }}媚值</span>
                        <!--                      <span class="generate_count">· 本次消耗{{ currentScore }}颜值</span>-->
                    </h-button>
                </div>
            </div>
            <div class="show_side">
                <div class="default_show set_center">
                    <div class="default_show_icon set_center" v-show="!creativeData.length">
                        <div class="loading_img" v-if="loadingStatus">
                            <img src="@/assets/images/loading.gif" alt="" />
                            <div class="coming_soon">精彩即将呈现...</div>
                        </div>
                        <div v-if="!loadingStatus">
                            <img class="create_icon" src="@/assets/images/create_default_1.png" alt="" />
                            <div>精彩在此呈现...</div>
                        </div>
                    </div>
                    <div class="default_show_icon pic_con set_center" v-show="creativeData.length">
                        <a-carousel>
                            <img :src="i" v-for="i in creativeData" :key="i" alt="" />
                        </a-carousel>
                    </div>
                </div>
                <div class="history_show">
                    <div class="history_show_list">
                        <SwiperCom v-show="generationsList.length" :symbols="generationsList" @togglePic="togglePic"></SwiperCom>
                    </div>
                </div>
            </div>
        </div>
        <MyWord ref="myWordModel" @editWordText="editWordText"></MyWord>
        <LoginModel ref="loginForm"></LoginModel>
    </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, onBeforeMount, onUnmounted, ref, watch } from 'vue';
import { message, UploadChangeParam } from 'ant-design-vue/es';

import { useRoute } from 'vue-router';
import MyWord from '@/views/home/components/myWord.vue';
import { createImage, getCreateRes, getStylesList, randomPrompts, savePrompts, uploadFile } from '@/api/home';
import { ApiRes, ApiWithoutRes } from '@/types/data';
import { styleListRes, stylesItemRes } from '@/types/home';
import { useUserStore } from '@/store';
import { baseImageUrl } from '@/util/utils';
import SwiperCom from '@/components/swiperCom/swiperCom.vue';
import { getMyList } from '@/api/creative';
import { pubilcGenerationsListRes, pubilcGenerationsPageRes } from '@/types/creative';
import LoginModel from '@/views/home/loginModel.vue';
import Header from '../../layout/modules/Header.vue';

const currentWord = ref();
const currentWordText = ref();
const prompts = ref<{ id?: number; prompt?: string; status?: string }>();
const getRandomPrompts = async () => {
    currentWordText.value = '';
    const res = (await randomPrompts()) as ApiRes<[{ id: number; prompt: string; status: string }]>;
    if (!res.code) {
        prompts.value = res.data[0];
        currentWordText.value = res.data[0].prompt;
    }
};

const savePromptsData = async () => {
    const res = (await savePrompts({ prompt: prompts.value?.prompt })) as ApiWithoutRes;
    if (!res.code) {
        message.success('保存成功');
    }
};

const editWordText = (text) => {
    currentWordText.value = text;
};

const myWordModel = ref();
const changeWord = (type) => {
    currentWord.value = type;
    if (type === 'my') {
        myWordModel.value.initData();
    } else if (type === 'random') {
        getRandomPrompts();
    } else if (type === 'save') {
        savePromptsData();
    }
};

const styleImageList = ref<stylesItemRes[]>();
const currentStyle = ref();
const currentStyleItemId = ref();
const changeStyleList = (data) => {
    currentStyle.value = data.id;
    styleImageList.value = data.styles;
    currentStyleItemId.value = data.styles[0].name_en;
};
// 获取风格列表
const styleList = ref<styleListRes[]>();
const getStylesListData = async () => {
    const res = (await getStylesList()) as ApiRes<styleListRes[]>;
    if (!res.code) {
        styleList.value = res.data;
        currentStyle.value = res.data[0].id;
        styleList.value?.forEach((i) => {
            i.styles?.forEach((item) => {
                item.image = `${import.meta.env.VITE_URL_IMAGEURL}/${item.image}`;
            });
        });
        styleImageList.value = JSON.parse(JSON.stringify(styleList.value[0].styles));
        currentStyleItemId.value = res.data[0].styles[0].name_en;
    }
};

const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
        message.error('格式错误，只能上传5M以内的文件');
        return false;
    }
    return true;
};

const currentImageUrl = ref('');
const currentPrompt = ref('');
const handleChange = (file: UploadChangeParam) => {
    if (file.file && file.file.response) {
        currentImageUrl.value = `${file.file.response.data.file_path}`;
    }
};

const customRequest = async (file) => {
    const formData = new FormData();
    formData.append('file', file.file);
    formData.append('type', 'Reference');
    const res = (await uploadFile(formData)) as any;
    if (!res.code) {
        currentImageUrl.value = `${res.data.file_path}`;
    }
};

const generationsList = ref<pubilcGenerationsListRes[]>([]);
const getGenerationsListData = async () => {
    const params = {
        page: 1,
        limit: 10,
    };
    const res = (await getMyList(params)) as ApiRes<pubilcGenerationsPageRes>;
    if (!res.code) {
        generationsList.value = res.data.data;
    }
};

const currentSize = ref(512);
const countList = [1, 2, 3, 4];
const currentCount = ref(1);

const loadingStatus = ref(false);
const creativeData = ref<string[]>([]);

// 获取生成结果
const createTimer = ref();
const getCreateResult = async (id) => {
    const res = (await getCreateRes({ id })) as ApiRes<any>;
    if (!res.code) {
        if (res.data.status === 'Processing') {
            createTimer.value = setTimeout(() => {
                getCreateResult(id);
            }, 2000);
        } else {
            loadingStatus.value = false;
            res.data.images?.forEach((item) => {
                creativeData.value.push(`${import.meta.env.VITE_URL_IMAGEURL}/${item}`);
            });
            getGenerationsListData();
        }
    }
};

const loginForm = ref();
const loginFirst = () => {
    loginForm.value.initData();
};

const onSubmit = async () => {
    if (!useUserStore().getToken) {
        return loginFirst();
        // return message.error('生成失败，请先登录');
    }
    creativeData.value = [];
    const params = {
        prompt: currentWordText.value,
        style: currentStyleItemId.value,
        width: currentSize.value,
        height: currentSize.value,
        quantity: currentCount.value,
        init_image: currentImageUrl.value,
    };
    loadingStatus.value = true;
    const res = (await createImage(params)) as ApiRes<any>;
    if (!res.code) {
        await useUserStore().setPoint();
        if (res.data.status === 'Processing') {
            getCreateResult(res.data.generation_id);
        } else {
            loadingStatus.value = false;
            res.data.images?.forEach((item) => {
                creativeData.value.push(`${import.meta.env.VITE_URL_IMAGEURL}/${item}`);
            });
            getGenerationsListData();
        }
    } else {
        loadingStatus.value = false;
    }
};
const currentScore = computed(() => {
    const size = currentSize.value === 512 ? 2 : 4;
    return size * currentCount.value;
});
watch(
    () => useUserStore().getToken,
    (newVal) => {
        if (!newVal) {
            location.reload();
        } else {
            getRandomPrompts();
            getStylesListData();
            getGenerationsListData();
        }
    }
);

const togglePic = (data) => {
    creativeData.value = [];
    nextTick(() => {
        const arr = JSON.parse(JSON.stringify(data));
        data.forEach((i, index) => {
            arr[index] = baseImageUrl + i;
        });
        creativeData.value = arr;
    });
};

const route = useRoute();
onBeforeMount(() => {
    if (!route.query.prompt) {
        getRandomPrompts();
    }
    getStylesListData();
    if (useUserStore().getToken) {
        getGenerationsListData();
    }
    if (route.query.local_thumbnail) {
        currentImageUrl.value = route.query.local_thumbnail as any;
    } else {
        currentImageUrl.value = '';
    }
    if (route.query.style) {
        currentStyle.value = route.query.style as any;
    }
    if (route.query.prompt) {
        currentWordText.value = route.query.prompt as any;
    }
});
onUnmounted(() => {
    clearTimeout(createTimer.value);
});
</script>

<style lang="less" scoped>
.page-container {
    display: flex;
    padding: 0;

    .card {
        margin-bottom: 5px;
    }

    .line {
        height: 100%;
        width: 4px !important;
        min-width: 4px !important;
        background: #0d131c;
    }

    .tool_side {
        width: 584px;
        padding: 24px 32px 156px;
        overflow-y: scroll;
        border-right: 4px solid #0d131c;

        .generate {
            width: 580px;
            position: fixed;
            bottom: 0;
            left: 0;
            height: 82px;
            padding: 16px 32px;
            backdrop-filter: blur(4px);
            background: rgba(0, 0, 0, 0.1);

            .ant-btn {
                height: 50px;
                font-weight: bold;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fff;
            }

            .generate_count {
                margin-left: 4px;
                font-weight: 500;
                font-size: 12px;
            }
        }
    }

    .show_side {
        width: calc(100% - 586px);
        height: 100%;
        position: relative;

        .default_show {
            height: calc(100% - 260px);
            border-bottom: 4px solid #0d131c;

            .default_show_icon {
                background: #1a242d;
                //background-image: url(@/assets/images/create_default.png);
                //min-width: 488px;
                //min-height: 488px;
                max-width: 622px;
                max-height: 622px;
                width: 100%;
                text-align: center;
                aspect-ratio: 1;
                color: #ccc;
                background-repeat: no-repeat;
                background-size: 780px;

                .create_icon {
                    margin-bottom: 20px;
                }

                .coming_soon {
                    margin-top: 20px;
                }
            }

            .icon_text_hidden {
                display: none;
            }
        }

        .pic_con {
            max-width: 622px;
            max-height: 622px;
            width: 100%;
            text-align: center;
            aspect-ratio: 1;
            color: #5d6267;

            .ant-carousel {
                width: 622px;
                height: 622px;
            }

            .ant-carousel :deep(.slick-slide) {
                text-align: center;
                width: 100%;
                height: 100%;
                background: #364d79;
                overflow: hidden;
            }

            .ant-carousel :deep(.slick-slide h3) {
                color: #fff;
            }

            .ant-carousel :deep(.slick-dots-bottom) {
                bottom: -26px;
            }

            .ant-carousel :deep(.slick-dots li) {
                width: 100px;
                height: 4px;
                margin-right: 24px;

                &:last-child {
                    margin-right: 0;
                }
            }

            .ant-carousel :deep(.slick-dots li button) {
                background-color: #30e38d;
                height: 6px;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        .history_show {
            padding: 16px 24px;
            //position: absolute;
            //bottom: 16px;
            width: 100%;
            height: 180px;
            //margin-top: 16px;
            .history_show_list {
                width: 100%;
                height: 100%;
                padding: 16px;
                background: #1a242d;
            }
        }
    }
}

.main_box {
    .word_area {
        height: 82px;
        background: #181f26;
        margin-bottom: 16px;
        border: 1px solid rgba(48, 227, 141, 0.17);
        //padding: 16px;
        color: #5d6267;

        .ant-input {
            border-color: rgba(48, 227, 141, 0.17);
            height: 100%;
            background: #161a24 !important;
            resize: none;
        }

        .ant-input:focus {
            box-shadow: none;
            background-color: #1a1f26;
        }
    }

    .word_btn {
        .word_btn_item {
            width: 152px;
            height: 31px;
            color: #fff;
            background: rgba(48, 227, 141, 0.05);
            border: 1px solid rgba(48, 227, 141, 0.1);
            cursor: pointer;

            &:nth-child(2) {
                margin: 0 16px;
            }

            &:hover {
                border-color: rgba(48, 227, 141, 0.3);
            }
        }

        .word_active {
            border-color: #30e38d;

            &:hover {
                border-color: #30e38d;
            }
        }
    }
}

.style_box {
    .selected_tag {
        height: 44px;
        background: rgba(17, 22, 20, 0.4);
        border-radius: 8px;
        border: 1px solid rgba(48, 227, 141, 0.14);
    }

    .selected_tag {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;

        .style_btn {
            width: 240px;
            height: 28px;
            color: #a2a5a8;
            cursor: pointer;

            &:hover {
                color: #30e38d;
            }
        }

        .tag_active {
            color: #131920;
            font-weight: bold;
            background: linear-gradient(134deg, #30e38d 0%, #1bffe1 100%);
            border-radius: 4px;
            border: 1px solid #30e38d;

            &:hover {
                color: #131920;
            }
        }
    }

    .pic_list {
        margin-top: 14px;
        display: flex;
        //justify-content: space-between;

        .pic_list_item {
            cursor: pointer;
            width: 110px;
            height: 110px;
            border-radius: 4px;
            border: 1px solid #193a37;
            position: relative;
            margin-right: 16px;

            &:last-child {
                margin-right: 0;
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 4px;
            }

            .pic_tag {
                position: absolute;
                bottom: 0px;
                left: 0;
                width: 108px;
                height: 20px;
                text-align: center;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.59) 52%, rgba(0, 0, 0, 0.83) 100%);
                border-radius: 0 0 3px 3px;
                z-index: 2;
            }

            &:hover {
                border-color: #30e38d;
            }
        }

        .style_active {
            border-color: #30e38d;
        }
    }
}

.upload_box {
    .upload_com {
        width: 92px;
        height: 92px;
        background: rgba(48, 227, 141, 0.05);
        border: 1px solid rgba(48, 227, 141, 0.1);
        text-align: center;

        &:last-child {
            cursor: pointer;
        }

        img {
            margin-bottom: 4px;
            margin-top: 22px;
            width: 26px;
            height: 26px;
        }

        &:hover {
            border-color: rgba(48, 227, 141, 0.3);
        }
    }

    .show_pic {
        position: relative;
        width: 92px;
        height: 92px;

        img {
            width: 92px;
            height: 92px;
        }

        .recover-tool {
            width: 92px;
            height: 92px;
            background: rgba(0, 0, 0, 0.3);
            position: absolute;
            display: none;
            top: 0;
            left: 0;

            .recover-tool-btn {
                cursor: pointer;
                width: 72px;
                text-align: center;
                height: 24px;
                line-height: 24px;
                background: rgba(0, 0, 0, 0.5);
                margin: 18px 10px;
                color: #fff;
                font-size: 12px;

                &:first-child {
                    margin-bottom: 0px;
                }
            }
        }

        &:hover {
            .recover-tool {
                display: block;
            }
        }
    }
}

.pic_box {
    justify-content: space-between;

    .size_btn {
        width: 236px;
        height: 50px;
        background: rgba(48, 227, 141, 0.1);
        border: 1px solid rgba(48, 227, 141, 0.1);
        cursor: pointer;

        &:hover {
            border-color: rgba(48, 227, 141, 0.3);
        }
    }

    .size_active {
        border-color: #30e38d;

        &:hover {
            border-color: #30e38d;
        }
    }
}

.count_box {
    .count_btn {
        width: 110px;
        height: 50px;
        background: rgba(48, 227, 141, 0.1);
        border: 1px solid rgba(48, 227, 141, 0.1);
        cursor: pointer;
        margin-right: 16px;

        &:hover {
            border-color: rgba(48, 227, 141, 0.3);
        }
    }

    .count_active {
        border: 1px solid #30e38d;

        &:hover {
            border-color: #30e38d;
        }
    }
}
</style>
