<template>
    <Header />
    <div class="base-container">
        <div class="page-container" ref="scrollDom">
            <div class="category">
                <div class="category_list set_vertical_center">
                    <div class="category_list_item set_center" :class="{ active: currentStyle === 1 }" @click="changeStyleList(1)">我的美媚</div>
                    <div class="category_list_item set_center" :class="{ active: currentStyle === 2 }" @click="changeStyleList(2)">美媚收藏</div>
                </div>
            </div>
            <div class="image_list">
                <a-list v-if="generationsList.length" :grid="{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 5, xxxl: 6 }" :data-source="generationsList">
                    <template #renderItem="{ item }">
                        <a-list-item>
                            <ImageItem :itemData="item" @get-list="deleteItem" :type="currentStyle === 1 ? '' : 'collect'"></ImageItem>
                        </a-list-item>
                    </template>
                </a-list>
            </div>
            <div v-if="loadingData" class="loading-data set_center">正在加载中...</div>
            <Footer class="footerCon" :class="{ fixedBottom: !generationsList.length || generationsList.length < 10 }"></Footer>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref, watch } from 'vue';
import { useScroll } from '@vueuse/core';
import ImageItem from '@/components/ImageItem/index.vue';
import { ApiRes } from '@/types/data';
import { getCollectsList, getMyList } from '@/api/creative';
import { collectGenerationsListRes, pubilcGenerationsListRes, pubilcGenerationsPageRes } from '@/types/creative';
import Footer from '@/components/Footer/Footer.vue';
import Header from '../../layout/modules/Header.vue';

const currentPage = ref(1);
const generationsList = ref<pubilcGenerationsListRes[]>([]);
const loadingData = ref(false);
const maxPage = ref();
const getGenerationsListData = async (form) => {
    loadingData.value = true;
    const params = {
        page: currentPage.value,
        limit: 20,
        ...form,
    };
    let res;
    if (currentStyle.value === 1) {
        res = (await getMyList(params)) as ApiRes<pubilcGenerationsPageRes>;
    } else {
        res = (await getCollectsList(params)) as ApiRes<collectGenerationsListRes>;
    }
    if (!res.code) {
        loadingData.value = false;
        maxPage.value = res.data.pages;
        if (params.page === 1) {
            generationsList.value = res.data.data;
        } else {
            generationsList.value.push(...res.data.data);
        }
    } else {
        loadingData.value = false;
    }
};

const deleteItem = (id) => {
    generationsList.value = generationsList.value?.filter((i) => i.id !== id);
};

const clearHistoryData = () => {
    generationsList.value = [];
    currentPage.value = 1;
};

const currentStyle = ref(1);
const changeStyleList = (id) => {
    clearHistoryData();
    currentStyle.value = id;
    getGenerationsListData({ page: 1 });
};

const scrollDom = ref<HTMLElement | null>(null);
const { arrivedState } = useScroll(scrollDom);

watch(
    () => arrivedState.bottom,
    (value) => {
        if (value && !loadingData.value && !arrivedState.top && maxPage.value - 1 > currentPage.value) {
            currentPage.value += 1;
            getGenerationsListData('');
        }
    }
);
onBeforeMount(async () => {
    await getGenerationsListData('');
});
</script>

<style lang="less" scoped>
.page-container {
    overflow: scroll;
    padding-bottom: 80px;

    .category {
        display: flex;
        justify-content: space-between;
        margin-bottom: 46px;

        .search {
            :deep(.ant-input-affix-wrapper) {
                width: 270px;
                height: 32px;
                background: rgba(48, 227, 141, 0.05);
                border-radius: 16px;
                border: 1px solid rgba(48, 227, 141, 0.1);
            }

            :deep(.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover) {
                border: 1px solid #30e38d;
            }
        }

        .category_list_item {
            width: 110px;
            height: 32px;
            background: rgba(48, 227, 141, 0.05);
            border-radius: 4px;
            border: 1px solid rgba(48, 227, 141, 0.1);
            cursor: pointer;
            margin-right: 16px;
            white-space: nowrap;

            &:hover {
                border-color: rgba(48, 227, 141, 0.3);
            }
        }

        .active {
            border-color: #30e38d;

            &:hover {
                border-color: #30e38d;
            }
        }
    }

    .image_list {
        //max-width: 1440px;
        margin: 0 auto;

        .image_list_item {
            margin-right: 24px;
            margin-bottom: 24px;
        }

        :deep(.ant-col) {
            display: flex;
            justify-content: center;
            margin-bottom: 24px;
        }

        :deep(.ant-spin-container) {
            background-color: #151a24 !important;
        }
    }
}

.loading-data {
    width: 100%;
    height: 80px;
    color: #a2a5a8;
}

:deep(.ant-empty-normal) {
    color: #fff;
}
</style>
