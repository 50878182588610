// 系统类Api接口
import { bodyPost, get, post } from '@/util/request';

/**
 * ⽣成图⽚
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const createImage = (p = {}) => post('/text2img', p);

/**
 * 随机词条
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const randomPrompts = (p = {}) => get('/random-prompts', p);

/**
 * 我的词条
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const myPrompts = (p = {}) => get('/user/prompts', p);

/**
 * 保存词条
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const savePrompts = (p = {}) => post('/user/edit-prompt', p);

/**
 * ⻛格列表
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const getStylesList = (p = {}) => get('/styles', p);

/**
 * 获取颜值说明
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const getPlatformWord = (p = {}) => get('/platform-settings', p);

/**
 * 获取⽀付配置
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const getRechargeConfigs = (p = {}) => get('/pay/recharge-configs', p);

/**
 * 获取⽀付⼆维码
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const getPayQrCode = (p = {}) => get('/pay/wx', p);

/**
 * 获取数字货币支付信息
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const getPayCryptoUP = (p = {}) => get('/pay/crypto-up', p);

/**
 * 支付轮询
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const getPayReS = (p = {}) => get('/pay/result', p);

/**
 * 更新支付状态
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const updatePayReS = (p = {}) => post('/pay/failed-update', p);

/**
 * 图片上传接口
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const uploadFile = (p = {}) => bodyPost('/file/upload', p);

/**
 * 生成图片轮询接口
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const getCreateRes = (p = {}) => get('/fetchQueuedImages', p);
