import CryptoJs from 'crypto-js';
import { message } from 'ant-design-vue';

const fruitCandy = CryptoJs.enc.Utf8.parse('ABCD123412ABCDEF');

// 密码加密
export function packMyPassword(word) {
    const src = CryptoJs.enc.Utf8.parse(word);
    const enc = CryptoJs.AES.encrypt(src, fruitCandy, {
        mode: CryptoJs.mode.ECB,
        padding: CryptoJs.pad.Pkcs7,
    });
    return enc.ciphertext.toString();
}

// 密码解密
export function decryptPassword(word) {
    const k = CryptoJs.enc.Hex.parse(word);
    const src = CryptoJs.enc.Base64.stringify(k);
    const decrypted = CryptoJs.AES.decrypt(src, fruitCandy, {
        mode: CryptoJs.mode.ECB,
        padding: CryptoJs.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJs.enc.Utf8);
}

// 解密
export function encoLocalPass(word, isEncode) {
    const code = CryptoJs.enc.Utf8.parse('LOCOPASSWORD');
    let enc;
    if (isEncode) {
        const src = CryptoJs.enc.Utf8.parse(word);
        enc = CryptoJs.AES.encrypt(src, code, {
            mode: CryptoJs.mode.ECB,
            padding: CryptoJs.pad.Pkcs7,
        });
        const res1 = enc.ciphertext.toString();
        return res1;
    }
    const encryptedHexStr = CryptoJs.enc.Hex.parse(word);
    const src = CryptoJs.enc.Base64.stringify(encryptedHexStr);

    enc = CryptoJs.AES.decrypt(src, code, {
        mode: CryptoJs.mode.ECB,
        padding: CryptoJs.pad.Pkcs7,
    });
    const decryptedStr = enc.toString(CryptoJs.enc.Utf8);
    return decryptedStr.toString();
}

// 下载文件方法
export function downloadFiles(url, title): void {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function (e: any) {
        if (e.target?.readyState === 4 && e.target?.status === 200) {
            const blob = this.response;
            // 转换一个blob链接
            const u = window.URL.createObjectURL(new Blob([blob]));
            const a = document.createElement('a');
            a.download = title;
            a.href = u;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    };
    xhr.send();
}

// 切换页面或标签中断未请求完成的接口
export function interruptCancel() {
    // 判断是否有存储的cancle
    if ((window as any)._axiosPromiseArr.length > 0) {
        (window as any)._axiosPromiseArr.forEach((item) => {
            // 给个标志，中断请求
            item('interrupt');
        });
        (window as any)._axiosPromiseArr = [];
    }
}

// 复制文本
export function copyToClipboard(text) {
    const textarea = document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.opacity = '0';
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    message.success('复制成功');
}

export const baseImageUrl = `${import.meta.env.VITE_URL_IMAGEURL}/`;
