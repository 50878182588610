/**
 * 校验规则库
 *
 * desc：存放用于表单校验的vee-validate方法，如手机号，日期，或其他公共规则正则表达式
 *
 */
// 必填手机号
export function phoneValid(rule, value, callback) {
    // 要求1起头11位长度，不进行23位校验避免运营商新增网段
    if (value && !value.match(/^1[0-9]{10}$/)) {
        callback(new Error('请输入正确格式的手机号'));
    } else {
        callback();
    }
}

// 必填手机号和座机
export function phoneLandlineValid(rule, value, callback) {
    // 要求1起头11位长度，不进行23位校验避免运营商新增网段
    const regOne = /^1\d{10}$/;
    const regTwo = /^[0-9]{3,8}$/;
    if (value && !value.match(regOne) && !value.match(regTwo)) {
        callback(new Error('请输入正确的号码'));
    } else {
        callback();
    }
}

// 非必填手机号
export function telephone(rule, value, callback) {
    // 要求1起头11位长度，不进行23位校验避免运营商新增网段
    if (value && !value.match(/^1[0-9]{10}$/)) {
        callback(new Error('请输入正确格式的手机号'));
    } else {
        callback();
    }
}

export const passRuleReg = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~#$@!%*&.,;_!\[\]\{\}()<>])[A-Za-z\d~#$@!%*&.,;_!\[\]\{\}()<>][^\s]{7,29}$/;
// 更改密码校验规则  8-30位 大小写+特殊字符+数字
export function passwordRule(rule, value, callback) {
    const reg = passRuleReg;
    if (value && !reg.test(value)) {
        callback(`新密码不符合密码规则,大小写+特殊字符+数字`);
    } else {
        callback();
    }
}

// 经度
export function longitude(rule, value, callback) {
    if (value !== '') {
        const reg = /^(\-|\+)?\d+(\.\d+)?$/;
        if (!reg.test(value) || value > 180 || value < -180) {
            callback(`经度范围在-180~180之间`);
        } else {
            callback();
        }
    } else {
        callback();
    }
}
// 纬度
export function latitude(rule, value, callback) {
    if (value !== '') {
        const reg = /^(\-|\+)?\d+(\.\d+)?$/;
        if (!reg.test(value) || value > 90 || value < -90) {
            callback(`纬度范围在-90~90之间`);
        } else {
            callback();
        }
    } else {
        callback();
    }
}
// 正实数(包括0)
export function isFloatingPointNumber(rule, value, callback) {
    const reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/;
    if (value && !reg.test(value)) {
        callback(`请输入2位小数内的正数`);
    } else if (value > 999.99) {
        callback(`最大数值为999.99`);
    } else {
        callback();
    }
}

// 正实数(包括0)
export function isAreaNumber(rule, value, callback) {
    const reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/;
    if (value && !reg.test(value)) {
        callback(`请输入2位小数内的正数`);
    } else if (value > 999999999.99) {
        callback(`最大数值为999999999.99`);
    } else {
        callback();
    }
}

// 地下层数
export function undergroundFloorCount(rule, value, callback) {
    const reg = /^[+]{0,1}(\d+)$/;
    if (!reg.test(value)) {
        callback(`请输入正整数`);
    } else if (value > 8) {
        callback(`地下层数不得超过8层`);
    } else {
        callback();
    }
}
// 地上层数
export function groundFloorCount(rule, value, callback) {
    const reg = /^[1-9]\d*$/;
    if (!reg.test(value)) {
        callback(`请输入正整数`);
    } else if (value > 200) {
        callback(`地上层数不得超过200层`);
    } else {
        callback();
    }
}

// 整数
export function integer(rule, value, callback) {
    const reg = /^-?(0|([1-9]\d{0,9})|([0-9]\d*.\d?[1-9]{1}))$/;
    if (value >= 9999 || value <= -9999) {
        callback('请输入-9999~9999之间的数字');
    } else if (value && !reg.test(value)) {
        callback(`请输入两位小数以内的数字`);
    } else {
        callback();
    }
}
// 时间间隔
export function intervalValue(rule, value, callback) {
    const reg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
    if (value && !reg.test(value)) {
        callback(`请输入正数`);
    } else if (value > 2147483647) {
        callback(`数值过大`);
    } else {
        callback();
    }
}

// 最多一位小数的数字
export function checkSingleNumber(rule, value, callback) {
    const reg = /^[0-9]+(.[0-9]{1})?$/;
    if (value && !reg.test(value)) {
        callback(`请输入最多一位小数的正数`);
    } else {
        callback();
    }
}

// 正整数（包括0）
export function positiveIntegerNumber(rule, value, callback) {
    const reg = /^[+]{0,1}(\d+)$/;
    if (value && !reg.test(value)) {
        callback(`请输入正整数`);
    } else {
        callback();
    }
}

// 正整数（不包括0）
export function notPositiveIntegerNumber(rule, value, callback) {
    const reg = /^[1-9]\d*$/;
    if (value && !reg.test(value)) {
        callback(`请输入正整数`);
    } else {
        callback();
    }
}
// 端子号
export function analogueNumber(rule, value, callback) {
    const reg = /^[+]{0,1}(\d+)$/;
    if (!reg.test(value)) {
        callback(`请输入正整数`);
    } else if (value > 128) {
        callback(`终端点位号不得超过128`);
    } else if (value < 1) {
        callback(`终端点位号不得小于1`);
    } else {
        callback();
    }
}

// 数字
export function Number(rule, value, callback) {
    const reg = /^(\-|\+)?\d+(\.\d+)?$/;
    if (!reg.test(value)) {
        callback(`请输入数字`);
    } else {
        callback();
    }
}

// 地址不超过128字符
export function address(rule, value, callback) {
    if (value.length > 128) {
        callback(`建筑地址不能超过128个字符`);
    } else {
        callback();
    }
}

// 只能是数字及字母
export function numberLetter(rule, value, callback) {
    const reg = /^[a-zA-Z0-9]+$/;
    if (value && !reg.test(value)) {
        callback(`只能输入数字及字母`);
    } else {
        callback();
    }
}

// 邮箱校验规则
export function emailReg(rule, value, callback) {
    // const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/;
    const reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value && !reg.test(value)) {
        callback(`请输入正确的邮箱格式`);
    } else {
        callback();
    }
}
// 必填统一社会信用代码
export function requiredUsci(rule, value, callback) {
    // 要求1起头11位长度，不进行23位校验避免运营商新增网段
    if (value && !value.match(/[\d|A-Z]{18}/)) {
        callback(new Error('请输入统一社会信用代码'));
    } else {
        callback();
    }
}

// 全局附件校验
export function regularFileValid(file, callback) {
    const extList = [
        'image/jpeg',
        'image/jpg',
        'application/x-rar-compressed',
        'application/octet-stream',
        'application/zip',
        'application/octet-stream',
        'application/x-zip-compressed',
        'multipart/x-zip',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/x-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/pdf',
    ];
    if (!extList.includes(file.type)) {
        callback('附件格式错误');
        return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
        callback('只能上传10M以内的文件');
        return false;
    }

    return true;
}
