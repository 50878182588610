/**
 * axios请求全局配置封装
 * websocket请求全局配置封装
 */
import axios from 'axios';
import { message } from 'ant-design-vue';
import throttle from 'lodash.throttle';
import { useUserStore } from '@/store/index';

// 接口错误返回弹出提示
const thrErrMessage = throttle(
    function (msgText) {
        // 节流弹窗
        message.error(msgText);
    },
    5000,
    { trailing: false }
);

// 设置超时时间
const _axios = axios.create({
    timeout: 10000,
});

_axios.defaults.baseURL = import.meta.env.VITE_API_PREFIX;

_axios.defaults.timeout = 100000;
export const baseURL = import.meta.env.VITE_API_PREFIX;

// 用来装cancel函数
(window as any)._axiosPromiseArr = [];
_axios.interceptors.request.use(
    (config) => {
        const userStore = useUserStore();
        config.cancelToken = new axios.CancelToken((cancel) => {
            (window as any)._axiosPromiseArr.push(cancel);
        });
        config.headers.authorization = userStore.getToken;
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);
_axios.interceptors.response.use(
    (response): any => {
        if (response === null) {
            thrErrMessage('请求数据失败,请稍后重试!');
            return;
        }

        if (response.status === 200) {
            const userStore = useUserStore();

            if (response.data.code !== 0) {
                thrErrMessage(response.data.msg);
            }

            // if (response.headers.authorization) {
            //     userStore.setToken(response.headers.authorization);
            // }
            if (response.data.data?.access_token) {
                userStore.setToken(response.data.data);
            }
            return Promise.resolve(response.data);
        }
        return Promise.resolve(response.data);
    },
    (error) => {
        const userStore = useUserStore();

        if (error.response && error.response.status) {
            switch (error.response.status) {
                // 其他错误，直接抛出错误提示
                case 500:
                    thrErrMessage('服务器异常,请稍后重试');
                    break;
                case 400:
                    thrErrMessage('业务异常,请稍后重试');
                    break;
                case 401 || 402:
                    thrErrMessage('请先登录');
                    userStore.clearToken();
                    userStore.setNeedLogin(true);
                    // router.push({ name: 'home' });
                    break;
                default:
                    thrErrMessage('请求数据失败, 请稍后重试!');
            }
        }
        return Promise.reject(error.response);
    }
);

/**
 * get请求
 * @param url {string} [请求的url地址]
 * @param params {object} [请求时携带的参数]
 * @returns {Promise<unknown>}
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        _axios
            .get(url, { params })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

/**
 * put请求
 * @param url {string} [请求的url地址]
 * @param params {object} [请求时携带的参数]
 * @returns {Promise<unknown>}
 */
export function put(url, params) {
    return new Promise((resolve, reject) => {
        _axios
            .put(url, params)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

/**
 * post请求
 * @param url {string}  [请求的url地址]
 * @param params {object} [请求时携带的参数]
 * @returns {Promise<unknown>}
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        _axios
            .post(url, '', { params })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

/**
 * post请求
 * @param url {string}  [请求的url地址]
 * @param params {object} [请求时携带的参数]
 * @returns {Promise<unknown>}
 */
export function bodyPost(url, params) {
    return new Promise((resolve, reject) => {
        _axios
            .post(url, params)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

/**
 * delete请求
 * @param url {string}  [请求的url地址]
 * @param params {object} [请求时携带的参数]
 * @returns {Promise<unknown>}
 */
export function del(url, params) {
    return new Promise((resolve, reject) => {
        _axios
            .delete(url, { params })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

// // 以下为处理全局导出的交互操作
// const loadingModal = ref();
// const infoModal = ref();
// const xhr = new XMLHttpRequest();
//
// /**
//  * 文件流下载请求--post的形式
//  * @param url {string}  [请求的url地址]
//  * @param fileName
//  * @returns {Promise<unknown>}
//  */
// export function postBlob(url, params) {
//     return new Promise((resolve, reject) => {
//         loadingModal.value = Modal.info({
//             title: '正在导出中，请稍候...',
//             icon: createVNode(LoadingOutlined),
//             okType: 'default',
//             okText: '关闭',
//             onOk() {
//                 xhr.abort();
//             },
//         });
//         const userStore = useUserStore();
//         xhr.open('post', _axios.defaults.baseURL + url);
//         xhr.setRequestHeader('authorization', userStore.getToken);
//         xhr.setRequestHeader('Client-Type', 'web');
//         xhr.setRequestHeader('Content-Type', 'application/json');
//         xhr.responseType = 'blob';
//         xhr.onload = function () {
//             if (this.status === 200) {
//                 // 延迟1.5s后执行，保留加载弹窗
//                 setTimeout(() => {
//                     loadingModal.value.destroy();
//                     // 从响应头获取文件名
//                     const res = xhr.getResponseHeader('Content-Disposition');
//                     let filename = res!.split('filename*=')[1];
//                     if (filename.indexOf('zh_cn') !== -1) {
//                         filename = filename?.replace(/^\s*/, '').replace(/\s*$/, '').replace("utf-8'zh_cn'", '');
//                     } else {
//                         filename = filename?.replace(/^\s*/, '').replace(/\s*$/, '').replace("utf-8''", '');
//                     }
//                     filename = decodeURI(filename);
//
//                     // dataCount为导出的数量
//                     const dataCount = xhr.getResponseHeader('Data-Count') || 0;
//                     const a = document.createElement('a');
//                     const url = window.URL.createObjectURL(this.response);
//                     a.href = url;
//                     a.download = filename;
//                     document.body.appendChild(a);
//                     infoModal.value = Modal.confirm({
//                         title: '导出成功，请下载导出文件',
//                         icon: createVNode(CheckCircleFilled, { style: { color: '#4dc600' } }),
//                         content: () =>
//                             h(
//                                 'div',
//                                 { style: { color: '#a19b9f' } },
//                                 dataCount
//                                     ? [
//                                           h('span', null, `${dataCount}条记录已成功导出到`),
//                                           h(
//                                               'span',
//                                               {
//                                                   style: { color: '#187eff', cursor: 'pointer', zIndex: '100' },
//                                                   onClick: () => {
//                                                       a.click();
//                                                   },
//                                               },
//                                               filename
//                                           ),
//                                       ]
//                                     : ''
//                             ),
//
//                         okText: '下载文件',
//                         cancelText: '关闭',
//                         onOk() {
//                             a.click();
//                             window.URL.revokeObjectURL(url);
//                         },
//                     });
//                 }, 1500);
//             }
//             reject();
//         };
//         xhr.send(JSON.stringify(params));
//     });
// }
//
// /**
//  * 文件流下载请求
//  * @param url {string}  [请求的url地址]
//  * @param fileName
//  * @returns {Promise<unknown>}
//  */
// export function getBlob(url) {
//     return new Promise((resolve, reject) => {
//         loadingModal.value = Modal.info({
//             title: '正在导出中，请稍候...',
//             icon: createVNode(LoadingOutlined),
//             okType: 'default',
//             okText: '关闭',
//             onOk() {
//                 xhr.abort();
//             },
//         });
//         const userStore = useUserStore();
//         const formData = new FormData();
//         xhr.open('get', _axios.defaults.baseURL + url);
//         xhr.setRequestHeader('authorization', userStore.getToken);
//         xhr.setRequestHeader('Client-Type', 'web');
//         xhr.responseType = 'blob';
//         xhr.onload = function () {
//             if (this.status === 200) {
//                 // 延迟1.5s后执行，保留加载弹窗
//                 setTimeout(() => {
//                     loadingModal.value.destroy();
//                     // 从响应头获取文件名
//                     const res = xhr.getResponseHeader('Content-Disposition');
//                     let filename = res!.split('filename*=')[1];
//                     if (filename.indexOf('zh_cn') !== -1) {
//                         filename = filename?.replace(/^\s*/, '').replace(/\s*$/, '').replace("utf-8'zh_cn'", '');
//                     } else {
//                         filename = filename?.replace(/^\s*/, '').replace(/\s*$/, '').replace("utf-8''", '');
//                     }
//                     filename = decodeURI(filename);
//
//                     // dataCount为导出的数量
//                     const dataCount = xhr.getResponseHeader('Data-Count') || 0;
//                     const a = document.createElement('a');
//                     const url = window.URL.createObjectURL(this.response);
//                     a.href = url;
//                     a.download = filename;
//                     document.body.appendChild(a);
//                     infoModal.value = Modal.confirm({
//                         title: '导出成功，请下载导出文件',
//                         icon: createVNode(CheckCircleFilled, { style: { color: '#4dc600' } }),
//                         content: () =>
//                             h(
//                                 'div',
//                                 { style: { color: '#a19b9f' } },
//                                 dataCount
//                                     ? [
//                                           h('span', null, `${dataCount}条记录已成功导出到`),
//                                           h(
//                                               'span',
//                                               {
//                                                   style: { color: '#187eff', cursor: 'pointer', zIndex: '100' },
//                                                   onClick: () => {
//                                                       a.click();
//                                                   },
//                                               },
//                                               filename
//                                           ),
//                                       ]
//                                     : ''
//                             ),
//
//                         okText: '下载文件',
//                         cancelText: '关闭',
//                         onOk() {
//                             a.click();
//                             window.URL.revokeObjectURL(url);
//                         },
//                     });
//                 }, 1500);
//             }
//             reject();
//         };
//         xhr.send(formData);
//     });
// }
//
// /**
//  * 文件流下载请求
//  * @param url {string}  [请求的url地址]
//  * @param fileName
//  * @returns {Promise<unknown>}
//  */
// export function getFileBlob(url) {
//     return new Promise((resolve, reject) => {
//         const userStore = useUserStore();
//         const xhr = new XMLHttpRequest();
//         const formData = new FormData();
//         xhr.open('get', _axios.defaults.baseURL + url);
//         xhr.setRequestHeader('authorization', userStore.getToken);
//         xhr.setRequestHeader('Client-Type', 'web');
//         xhr.responseType = 'blob';
//         xhr.onload = function () {
//             if (this.status === 200) {
//                 const res = xhr.getResponseHeader('Content-Disposition');
//                 let filename = res!.split('filename*=')[1];
//                 if (filename.indexOf('zh_cn') !== -1) {
//                     filename = filename?.replace(/^\s*/, '').replace(/\s*$/, '').replace("utf-8'zh_cn'", '');
//                 } else {
//                     filename = filename?.replace(/^\s*/, '').replace(/\s*$/, '').replace("utf-8''", '');
//                 }
//                 filename = decodeURI(filename);
//                 const a = document.createElement('a');
//                 const url = window.URL.createObjectURL(this.response);
//                 a.href = url;
//                 a.download = filename;
//                 document.body.appendChild(a);
//                 a.click();
//                 window.URL.revokeObjectURL(url);
//             } else {
//                 reject();
//             }
//         };
//         xhr.send(formData);
//     });
// }

export default _axios;
