<template>
    <a-button v-bind="attrs">
        <slot></slot>
    </a-button>
</template>

<script lang="ts" setup>
import { useAttrs } from 'vue';

const attrs = useAttrs();
</script>

<style lang="less" scoped>
.ant-btn {
    width: 100%;
    height: 32px;
    color: #131920;
    font-weight: bold;
    background: linear-gradient(134deg, #30e38d 0%, #1bffe1 100%);
    border-radius: 4px;
    border: none;
}

.ant-btn:hover,
.ant-btn:focus {
    color: #131920;
    background: linear-gradient(134deg, #50ffab 0%, #58ffe9 100%);
    box-shadow: 0px 1px 20px 0px rgba(48, 227, 141, 0.46);
}
</style>
