<template>
    <div class="swiper">
        <div v-show="false" class="watch-list-arrow watch-list-arrow--left" @click="onPageLeft">
            <div class="watch-list-arrow-btn">
                <left-outlined />
            </div>
        </div>
        <div ref="currencyItemsRef" class="currency-items">
            <div class="currency-item" v-for="(item, index) in props.symbols" :key="index">
                <img :src="baseImageUrl + item.local_thumbnail" alt="" @click="showHistoryPic(item)" />
            </div>
        </div>
        <div v-show="false" class="watch-list-arrow watch-list-arrow--right" @click="onPageRight">
            <div class="watch-list-arrow-btn">
                <right-outlined />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue';
import { baseImageUrl } from '@/util/utils';

type Props = {
    symbols: any[];
};
const props = withDefaults(defineProps<Props>(), {});

const emits = defineEmits(['togglePic']);
const showHistoryPic = (data) => {
    emits('togglePic', data.images);
};

const symbols = ref(['BTC111', 'ETH', 'XRP', 'LTC', 'BCH', 'ADA', 'DOGE', 'DOT', 'LINK', 'UNI1', 'UNI2', 'UNI3', 'UNI4', 'UNI5', 'UNI6', 'UNI999']);

const currencyItemsRef = ref<any>(null);

// 左右箭头滑动
const onPageLeft = () => {
    currencyItemsRef.value.scroll({
        left: currencyItemsRef.value.scrollLeft - currencyItemsRef.value.offsetWidth,
        behavior: 'smooth',
    });
};

const onPageRight = () => {
    currencyItemsRef.value.scroll({
        left: currencyItemsRef.value.scrollLeft + currencyItemsRef.value.offsetWidth,
        behavior: 'smooth',
    });
};

// 鼠标滑动
const control = reactive({
    isDown: false, // 是否按下鼠标
    startX: 0, // 鼠标起始位置
    scrollLeft: 0, // 滚动条位置
});

const move = (e) => {
    if (!control.isDown) return;
    e.preventDefault();
    const x = e.pageX - currencyItemsRef.value.offsetLeft;
    const walk = (x - control.startX) * 2; // 滑动距离
    currencyItemsRef.value.scrollLeft = control.scrollLeft - walk;
};

onMounted(() => {
    console.log('dom', currencyItemsRef.value);
    // 总结web端实现滑动，就是对鼠标按下、鼠标松开、鼠标移动事件进行监听
    currencyItemsRef.value.addEventListener('mousedown', (e) => {
        control.isDown = true;
        control.startX = e.pageX - currencyItemsRef.value.offsetLeft;
        control.scrollLeft = currencyItemsRef.value.scrollLeft;
    });

    currencyItemsRef.value.addEventListener('mouseup', (e) => {
        control.isDown = false;
    });

    currencyItemsRef.value.addEventListener('mousemove', move);
});
</script>
<!--

在这个示例中，我们使用 vue 的 ref 函数创建了 currencyItemsRef 引用，它指向滚动容器的 div 元素。我们还定义了 onPageLeft 和 onPageRight 方法，用于处理点击左右箭头时的滑动事件。

在 onPageLeft 方法中，我们通过减去滚动容器的宽度，实现了向左滑动一个容器宽度的距离。

同样地，在 onPageRight 方法中，我们通过加上滚动容器的宽度，实现了向右滑动一个容器宽度的距离。

通过点击左右箭头按钮，你可以看到滚动容器会相应地滑动，展示出不同的项目。

 -->

<style lang="less" scoped>
.swiper {
    display: flex;
    align-items: center;
    //width: 800px;
    height: 100%;
    overflow: hidden;
}

.watch-list-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.watch-list-arrow-btn {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: #30e38d;
    font-weight: bold;
}

.currency-items {
    width: 100%;
    display: flex;
    gap: 24px;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        margin-top: 8px;
        padding-top: 8px;
        width: 4px;
        //display: none;
    }
}

.currency-item {
    flex: 0 0 auto;
    width: 116px;
    height: 116px;
    border-radius: 4px;
    background-color: lightblue;
    border: 1px solid #193a37;

    img {
        margin-right: 16px;
        width: 116px;
        height: 116px;
        cursor: pointer;
        border-radius: 4px;
    }

    img:last-child {
        margin-right: 0;
    }
}
</style>
