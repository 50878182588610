import { get, post } from '@/util/request';

/**
 * 获取美媚列表
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const getGenerationsList = (p = {}) => get('/user/public-generations', p);

/**
 * 获取美媚详情
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const getGenerationsDetail = (p = {}) => get('/text2img', p);

/**
 * 收藏
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const collectItem = (p = {}) => post('/user/collect', p);

/**
 * 获取我的收藏列表
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const getCollectsList = (p = {}) => get('/user/collects', p);

/**
 * 获取我的列表
 * @param p {object}
 * @returns {Promise<unknown>}
 */
export const getMyList = (p = {}) => get('/user/my-generations', p);
