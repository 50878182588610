<template>
    <div class="footer">
        <div class="footer_main">
            <div class="tag">Powered by meimeiai.cc</div>
            <div class="tag">Email: support@meimeiai.cc</div>
            <div class="tag">Telegram: @meimei_ai</div>
        </div>
    </div>
</template>

<script setup lang="ts"></script>

<style lang="less" scoped>
.footer {
    z-index: 10;
    background-color: #151a24 !important;
    width: 100%;
    max-width: 1152px;
    height: 100px;
    padding: 24px;

    .footer_main {
        border-top: 1px solid #6c727f;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        padding: 24px 12px;

        .tag {
            color: #fff;
            font-weight: bold;
        }

        .tag:first-child {
            color: #6c727f;
        }
    }
}
</style>
