import { useIntersectionObserver } from '@vueuse/core';
import { Directive } from 'vue';

export const imgLazy: Directive<HTMLInputElement, any> = (el, binding) => {
    useIntersectionObserver(
        el, // 要监听谁把谁传过来
        ([{ isIntersecting }]) => {
            // console.log(isIntersecting, binding.value);
        }
    );
};
