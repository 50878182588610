<template>
    <div class="base-container">
        <div class="page-container set_level_center">
            <div class="main_box">
                <div class="return_button set_vertical_center" @click="router.back()">
                    <img src="@/assets/images/layout/return.png" alt="" />
                    <div style="margin-left: 8px">返回</div>
                </div>
                <div class="personal">
                    <div class="personal_info">
                        <div class="title">基本信息</div>

                        <div class="personal_container">
                            <div class="head_image set_level_center">
                                <a-avatar :src="userDetail?.avatar" class="avatar"></a-avatar>
                            </div>
                            <div class="personal_form">
                                <a-form :hideRequiredMark="true" ref="infoFormRef" :model="infoForm" :rules="infoRules" class="form" layout="vertical">
                                    <a-form-item label="昵称" name="name">
                                        <h-input v-model:value="infoForm.name" placeholder="请输入您的姓名" />
                                    </a-form-item>
                                    <a-form-item label="Telegram" name="mobile">
                                        <h-input v-model:value="infoForm.mobile" placeholder="@" />
                                    </a-form-item>
                                    <a-form-item label="邮箱" name="email">
                                        <h-input v-model:value="infoForm.email" disabled="" placeholder="请输入邮箱" />
                                    </a-form-item>
                                </a-form>
                                <h-button class="save_btn" @click="onInfoSubmit">保存</h-button>
                            </div>
                        </div>
                    </div>
                    <div class="personal_set">
                        <div class="title">更改密码</div>
                        <div class="personal_container">
                            <div class="personal_form">
                                <a-form :hideRequiredMark="true" ref="pasFormRef" :model="pasForm" :rules="pasRules" class="form" layout="vertical">
                                    <a-form-item label="当前密码" name="current_password">
                                        <a-input-password v-model:value="pasForm.current_password" placeholder="请输入当前密码" />
                                    </a-form-item>
                                    <a-form-item label="新密码" name="password">
                                        <a-input-password v-model:value="pasForm.password" placeholder="请输入密码" />
                                    </a-form-item>
                                    <a-form-item label="确认新密码" name="password_confirmation">
                                        <a-input-password v-model:value="pasForm.password_confirmation" placeholder="请再次输入新密码" />
                                    </a-form-item>
                                </a-form>
                                <h-button class="save_btn" @click="onPasSubmit">保存</h-button>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                    <span style="display: inline-block; height: 64px"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { onBeforeMount, ref } from 'vue';
import { message } from 'ant-design-vue/es';
import { editPas, editUserInfo, getUserDetail } from '@/api/system';
import { ApiRes, loginResult, userDetailResult } from '@/types/data';
import { useUserStore } from '@/store';
import { emailReg } from '@/util/validateRules';
import Footer from '@/components/Footer/Footer.vue';

const router = useRouter();
const infoForm = ref({
    name: '',
    email: '',
    mobile: '',
});
const infoRules = {
    mobile: [
        {
            required: false,
            message: '请输入telegram',
            trigger: 'blur',
        },
    ],
    email: [{ validator: emailReg }],
};
const pasRules = {
    current_password: [
        {
            required: true,
            message: '请输入当前密码',
            trigger: 'blur',
        },
    ],
    password: [
        {
            required: true,
            message: '请输入新密码',
            trigger: 'blur',
        },
    ],
    password_confirmation: [
        {
            required: true,
            message: '请再次输入新密码',
            trigger: 'blur',
        },
    ],
};
const pasForm = ref({
    current_password: '',
    password: '',
    password_confirmation: '',
});

const infoFormRef = ref();
const onInfoSubmit = () => {
    infoFormRef.value.validate().then(async () => {
        const params = { ...JSON.parse(JSON.stringify(infoForm.value)) };
        const res = (await editUserInfo(params)) as ApiRes<loginResult>;
        if (!res.code) {
            message.success('保存成功');
        }
    });
};

const pasFormRef = ref();
const onPasSubmit = () => {
    pasFormRef.value.validate().then(async () => {
        const params = { ...JSON.parse(JSON.stringify(pasForm.value)) };
        const res = (await editPas(params)) as ApiRes<loginResult>;
        if (!res.code) {
            message.success('保存成功');
        }
    });
};
const userDetail = ref<userDetailResult>();
const getUserDetailData = async () => {
    const res = (await getUserDetail()) as ApiRes<userDetailResult>;
    if (!res.code) {
        userDetail.value = res.data;
        userDetail.value.avatar = `${import.meta.env.VITE_URL_IMAGEURL}/${userDetail.value?.avatar}`;
        useUserStore().setUserInfo(userDetail.value);
        infoForm.value = {
            name: res.data.name,
            email: res.data.email,
            mobile: res.data.mobile,
        };
    }
};
onBeforeMount(() => {
    getUserDetailData();
});
</script>

<style lang="less" scoped>
.page-container {
    overflow: scroll;
    flex-direction: column;
    align-items: center;

    .main_box {
        margin: 0 auto;
        color: #a2a5a8;
        padding-bottom: 100px;
        height: calc(100% - 100px);
        margin-bottom: 100px;

        .return_button {
            cursor: pointer;
            margin: 0 0 24px;
        }

        .personal {
            height: 344px;
            color: #fff;

            & > div {
                background: #1f2a38;
                border-radius: 4px;

                .title {
                    padding: 16px 24px;
                    font-size: 16px;
                    border-bottom: 1px solid #161e2a;
                }

                .personal_container {
                    padding: 30px 24px;
                    display: flex;

                    .personal_form {
                        width: 100%;
                        text-align: right;

                        .ant-form-item {
                            margin-bottom: 16px;
                        }

                        :deep(.ant-form-item-label) {
                            padding-bottom: 4px;

                            & > label {
                                color: #fff !important;
                            }
                        }

                        .save_btn {
                            width: 65px;
                            margin-top: 8px;
                        }
                    }
                }
            }

            .personal_info {
                width: 780px;
                height: 428px;
                margin-bottom: 24px;

                .avatar {
                    width: 120px;
                    height: 120px;
                    background: #2b7eff !important;
                    margin-right: 32px;
                }
            }

            .personal_set {
                width: 780px;
                height: 428px;
            }
        }
    }
}

:deep(.ant-form-item-explain-error) {
    text-align: left;
}
</style>
