<template>
    <div>
        <h-modal :width="958" :maskClosable="false" :footer="null" v-model:visible="visible">
            <div class="main_box set_vertical_center">
                <div class="pic_container">
                    <a-carousel>
                        <img :src="i" v-for="i in creativeDetail?.images" :key="i" alt="" />
                    </a-carousel>
                </div>
                <div class="info_container">
                    <div class="info_item">
                        <div class="info_item_title">画面描述</div>
                        <div class="info_item_value">{{ creativeDetail?.prompt }}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item_title">图片风格</div>
                        <div class="info_item_value">{{ creativeDetail?.style }}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item_title">图片尺寸</div>
                        <div class="info_item_value">{{ creativeDetail?.width + '*' + creativeDetail?.width }}</div>
                    </div>
                    <div class="info_item">
                        <div class="info_item_title">生成日期</div>
                        <div class="info_item_value">{{ dayjs(creativeDetail?.created_at).format('YYYY-MM-DD') }}</div>
                    </div>
                    <div class="info_btn set_level_center">
                        <div class="info_btn_item set_center" @click="handleClick(1)">复制描述</div>
                        <div class="info_btn_item set_center" @click="handleClick(2)">生成同款</div>
                        <div class="info_btn_item set_center" @click="handleClick(3)">{{ collectText }}</div>
                    </div>
                </div>
            </div>
        </h-modal>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import dayjs from 'dayjs';
import { message } from 'ant-design-vue/es';
import { collectItem, getGenerationsDetail } from '@/api/creative';
import { ApiRes, ApiWithoutRes } from '@/types/data';
import { pubilcGenerationsListRes } from '@/types/creative';
import { copyToClipboard } from '@/util/utils';
import router from '@/router';
import { useUserStore } from '@/store';

const visible = ref<boolean>(false);

const collectText = ref('收藏');
const baseImageUrl = `${import.meta.env.VITE_URL_IMAGEURL}/`;
const creativeDetail = ref<pubilcGenerationsListRes>();
const showDetail = async (data) => {
    const res = (await getGenerationsDetail({ id: data.id })) as ApiRes<pubilcGenerationsListRes>;
    if (!res.code) {
        creativeDetail.value = res.data;
        const arr = [] as string[];
        creativeDetail.value?.images.forEach((i) => {
            arr.push(baseImageUrl + i);
        });
        creativeDetail.value.images = arr;
        collectText.value = res.data.is_collected == 'Yes' ? '取消收藏' : '收藏';

        await useUserStore().setPoint();
    }
};

const collectCreative = async () => {
    const res = (await collectItem({ collect_id: creativeDetail.value?.id })) as ApiWithoutRes;
    if (!res.code) {
        message.success(collectText.value + '成功');
        collectText.value = collectText.value == '收藏' ? '取消收藏' : '收藏';
    }
};

const handleClick = (type) => {
    if (type === 1) {
        copyToClipboard(creativeDetail.value?.prompt);
    } else if (type === 2) {
        cancel();
        router.push({ name: 'create', query: { 
                local_thumbnail: creativeDetail.value?.local_thumbnail,
                style: creativeDetail.value?.style_en,
                prompt: creativeDetail.value?.prompt,
            } 
        });
    } else {
        collectCreative();
    }
};

const initData = async (data) => {
    await showDetail(data);
    visible.value = true;
};

const cancel = () => {
    visible.value = false;
};

defineExpose({
    initData,
});
</script>

<style lang="less" scoped>
.main_box {
    .pic_container {
        margin-right: 42px;
        width: 468px;
        margin-bottom: 48px;

        .ant-carousel :deep(.slick-slide) {
            text-align: center;
            width: 468px;
            height: 468px;
            background: #364d79;
            overflow: hidden;
        }

        .ant-carousel :deep(.slick-slide h3) {
            color: #fff;
        }

        .ant-carousel :deep(.slick-dots-bottom) {
            bottom: -36px;
        }

        .ant-carousel :deep(.slick-dots li) {
            width: 100px;
            height: 4px;
            margin-right: 24px;

            &:last-child {
                margin-right: 0;
            }
        }

        .ant-carousel :deep(.slick-dots li button) {
            background-color: #30e38d;
            height: 6px;
        }

        img {
            width: 468px;
            height: 468px;
        }
    }

    .info_container {
        width: 401px;
        height: 492px;
        display: flex;
        flex-direction: column;
        position: relative;

        .info_item {
            width: 100%;
            margin-bottom: 32px;

            &:last-child {
                margin-bottom: 0;
            }

            .info_item_title {
                color: #a2a5a8;
                margin-bottom: 8px;
            }
        }

        .info_btn {
            position: absolute;
            bottom: 36px;
            left: 0;

            .info_btn_item {
                width: 126px;
                height: 44px;
                background: rgba(48, 227, 141, 0.05);
                border-radius: 4px;
                border: 1px solid rgba(48, 227, 141, 0.1);
                margin-right: 16px;
                cursor: pointer;

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    border-color: rgba(48, 227, 141, 0.3);
                }
            }

            .active {
                border-color: #30e38d;

                &:hover {
                    border-color: #30e38d;
                }
            }
        }
    }
}
</style>
