import { defineStore } from 'pinia';
import { ApiRes, userDetailResult } from '@/types/data';
import { getUserDetail } from '@/api/system';

const useUserStore = defineStore('user', {
    state: () => {
        return {
            token: <string>'',
            expireTime: <string>'',
            userInfo: null as userDetailResult | null,
            needLogin: false,
        };
    },
    getters: {
        getToken(): string {
            return this.token || (localStorage.getItem('token') as any)?.token;
        },
        getUserInfo(): userDetailResult | null {
            return this.userInfo;
        },
        getNeedLogin(): boolean {
            return this.needLogin;
        },
    },
    actions: {
        setNeedLogin(bool) {
            this.needLogin = bool;
        },
        setToken(token) {
            this.token = `Bearer ${token.access_token}`;
            this.expireTime = token.expire_at;
        },
        setUserInfo(userInfo) {
            this.userInfo = userInfo;
        },
        async getUserInfoData() {
            const res = (await getUserDetail()) as ApiRes<userDetailResult>;
            this.userInfo = res.data;
            this.userInfo.avatar = `${import.meta.env.VITE_URL_IMAGEURL}/${this.userInfo.avatar}`;
        },
        async setPoint() {
            const res = (await getUserDetail()) as ApiRes<userDetailResult>;
            this.userInfo!.point = res.data.point;
        },
        async clearToken() {
            // if (this.token) {
            //     await logoutSys();
            // }
            this.token = '';
            this.expireTime = '';
            this.userInfo = null;
            localStorage.clear();
        },
        clearUser() {
            this.userInfo = null;
        },
    },
    persist: {
        enabled: true,
        strategies: [
            { key: 'token', storage: localStorage, paths: ['token'] },
            { key: 'expireTime', storage: localStorage, paths: ['expireTime'] },
            { key: 'userInfo', storage: localStorage, paths: ['userInfo'] },
        ],
    },
});

export default useUserStore;
