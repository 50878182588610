import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/creativeSquare/index.vue';
import createPage from '@/views/home/index.vue';
import myCreationPage from '@/views/creativeSquare/myCreative.vue';
import settingPage from '@/views/personalSetting/index.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/create',
    name: 'create',
    component: createPage,
  },
  {
    path: '/my-creations',
    name: 'my-creations',
    component: myCreationPage,
  },
  {
    path: '/setting',
    name: 'setting',
    component: settingPage,
  },
    // { path: '/', redirect: '/home' },
    // {
    //     path: '/',
    //     name: 'home',
    //     component: index,
    //     meta: {
    //         layout: false,
    //         menu: false,
    //     },
    //     children: [
    //         {
    //             path: '/creates',
    //             name: 'creates',
    //             component: () => import('@/views/home/index.vue'),
    //             meta: {
    //                 title: '首页',
    //                 layout: true,
    //                 menu: false,
    //                 icon: 'LoginOutlined',
    //             },
    //         },
    //         {
    //             path: '/personalSetting',
    //             name: 'personalSetting',
    //             component: () => import('@/views/personalSetting/index.vue'),
    //             meta: {
    //                 title: '个人设置',
    //                 layout: true,
    //                 menu: false,
    //                 icon: 'LoginOutlined',
    //             },
    //         },
    //         {
    //             path: '/',
    //             name: 'home',
    //             component: () => import('@/views/creativeSquare/index.vue'),
    //             meta: {
    //                 title: '美媚广场',
    //                 layout: true,
    //                 menu: false,
    //                 icon: 'LoginOutlined',
    //             },
    //         },
    //         {
    //             path: '/myCreative',
    //             name: 'myCreative',
    //             component: () => import('@/views/creativeSquare/myCreative.vue'),
    //             meta: {
    //                 title: '我的美媚',
    //                 layout: true,
    //                 menu: false,
    //                 icon: 'LoginOutlined',
    //             },
    //         },
    //     ],
    // },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
