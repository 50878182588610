import type { Component } from 'vue';
import HModal from './HModal/HModal.vue';
import HSelect from './HSelect/index.vue';
import HButton from './pubilcCom/HButton.vue';
import HInput from '@/components/pubilcCom/HInput.vue';
import ToolBox from '@/components/pubilcCom/ToolBox.vue';

const components: {
    [propName: string]: Component;
} = {
    HModal,
    HSelect,
    HButton,
    HInput,
    ToolBox,
};
export default components;
