<template>
    <a-select :getPopupContainer="(trigger) => trigger.parentNode" allow-clear dropdownClassName="dropDown" v-bind="attrs"> </a-select>
</template>

<script lang="ts" setup>
import { useAttrs } from 'vue';

const attrs = useAttrs();
</script>

<style lang="less" scoped>
:deep(.dropDown) {
    text-align: left;
}
</style>
