<template>
    <div class="swiper">
        <div ref="currencyItemsRef" class="currency-items">
            <div class="currency-item category_list_item set_center" :class="{ active: currentStyle === i.name_en }" v-for="i in props.symbols" :key="i.name_en" @click="changeStyleList(i.name_en)">
                {{ i.name_cn }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue';

type Props = {
    symbols: any[];
};
const props = withDefaults(defineProps<Props>(), {});

const emits = defineEmits(['toggleTag']);

const currentStyle = ref('');
const changeStyleList = (id) => {
    currentStyle.value = id;
    emits('toggleTag', currentStyle.value);
};

const currencyItemsRef = ref<any>(null);

// 左右箭头滑动
const onPageLeft = () => {
    currencyItemsRef.value.scroll({
        left: currencyItemsRef.value.scrollLeft - currencyItemsRef.value.offsetWidth,
        behavior: 'smooth',
    });
};

const onPageRight = () => {
    currencyItemsRef.value.scroll({
        left: currencyItemsRef.value.scrollLeft + currencyItemsRef.value.offsetWidth,
        behavior: 'smooth',
    });
};

// 鼠标滑动
const control = reactive({
    isDown: false, // 是否按下鼠标
    startX: 0, // 鼠标起始位置
    scrollLeft: 0, // 滚动条位置
});

const move = (e) => {
    if (!control.isDown) return;
    e.preventDefault();
    const x = e.pageX - currencyItemsRef.value.offsetLeft;
    const walk = (x - control.startX) * 2; // 滑动距离
    currencyItemsRef.value.scrollLeft = control.scrollLeft - walk;
};

onMounted(() => {
    console.log('dom', currencyItemsRef.value);
    // 总结web端实现滑动，就是对鼠标按下、鼠标松开、鼠标移动事件进行监听
    currencyItemsRef.value.addEventListener('mousedown', (e) => {
        control.isDown = true;
        control.startX = e.pageX - currencyItemsRef.value.offsetLeft;
        control.scrollLeft = currencyItemsRef.value.scrollLeft;
    });

    currencyItemsRef.value.addEventListener('mouseup', (e) => {
        control.isDown = false;
    });

    currencyItemsRef.value.addEventListener('mousemove', move);
});
</script>
<!--

在这个示例中，我们使用 vue 的 ref 函数创建了 currencyItemsRef 引用，它指向滚动容器的 div 元素。我们还定义了 onPageLeft 和 onPageRight 方法，用于处理点击左右箭头时的滑动事件。

在 onPageLeft 方法中，我们通过减去滚动容器的宽度，实现了向左滑动一个容器宽度的距离。

同样地，在 onPageRight 方法中，我们通过加上滚动容器的宽度，实现了向右滑动一个容器宽度的距离。

通过点击左右箭头按钮，你可以看到滚动容器会相应地滑动，展示出不同的项目。

 -->

<style lang="less" scoped>
.swiper {
    display: flex;
    align-items: center;
    max-width: 1200px;
    height: 100%;
    overflow: hidden;
}

.watch-list-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-right: 16px;

    &:last-child {
        margin-right: 0;
        margin-left: 16px;
    }
}

.watch-list-arrow-btn {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #30e38d;
    font-weight: bold;
}

.currency-items {
    display: flex;
    gap: 16px;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        display: none;
    }
}

.currency-item {
    flex: 0 0 auto;
    width: 116px;
    height: 116px;
    border-radius: 4px;
    background-color: lightblue;
    border: 1px solid #193a37;
}

.category_list_item {
    width: 110px;
    height: 32px;
    background: rgba(48, 227, 141, 0.05);
    border-radius: 4px;
    border: 1px solid rgba(48, 227, 141, 0.1);
    cursor: pointer;
    white-space: nowrap;

    &:hover {
        border-color: rgba(48, 227, 141, 0.3);
    }
}

.active {
    border-color: #30e38d;

    &:hover {
        border-color: #30e38d;
    }
}
</style>
