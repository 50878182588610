<template>
    <a-modal cancel-text="取消" :footer="null" ok-text="确认" centered v-bind="attrs" :bodyStyle="modelStyle">
        <slot></slot>
        <div class="confirm_btn set_center" v-show="props.needConfirm">
            <h-button class="cancelBtn" style="margin-right: 16px" @click="cancelEvent">取消</h-button>
            <h-button @click="okEvent">确定</h-button>
        </div>
    </a-modal>
</template>
<script lang="ts" setup>
import { useAttrs } from 'vue';

const attrs = useAttrs();
const modelStyle = {
    backgroundColor: '#1f2a38',
    color: '#fff',
};
const props = withDefaults(defineProps<{ needConfirm?: boolean }>(), {
    needConfirm: false,
});
const emit = defineEmits(['cancelEvent', 'okEvent']);
const cancelEvent = () => {
    emit('cancelEvent');
};
const okEvent = () => {
    emit('okEvent');
};
</script>
<style lang="less" scoped>
:deep(.ant-modal-content) {
    background-color: #1f2a38 !important;
}

:deep(.ant-modal-title) {
    color: #fff !important;
}

.confirm_btn {
    margin-top: 48px;

    .cancelBtn {
        background: rgba(48, 227, 141, 0.05);
        color: #fff !important;

        &:hover {
            background: rgba(48, 227, 141, 0.05);
            color: #fff !important;
            box-shadow: none;
        }

        &:focus {
            background: rgba(48, 227, 141, 0.05);
            color: #fff !important;
            box-shadow: none;
        }
    }
}
</style>
