import { defineStore } from 'pinia';

interface Route {
    path: string;
    breadcrumbName: string | undefined;
}
const useLayoutStore = defineStore('layout', {
    state: () => {
        return {
            menuCollapsed: false,
            breadCrumbRoute: [] as Route[],
            keepAlivePage: [] as string[],
        };
    },
    getters: {
        getBreadCrumbRoute(state) {
            return state.breadCrumbRoute;
        },
        getKeepAlivePage(state) {
            return state.keepAlivePage;
        },
    },
    actions: {
        menuTrigger() {
            this.menuCollapsed = !this.menuCollapsed;
        },
        setKeepAlivePage(data: string[], code: number) {
            if (code) {
                this.keepAlivePage = [];
                this.keepAlivePage = data;
            } else {
                this.keepAlivePage.push(...data);
            }
            console.log(this.keepAlivePage);
        },
        setBreadCrumbRoute(data: Route[], code: number) {
            if (code) {
                this.breadCrumbRoute = data;
            } else {
                // arr用来存储面包屑名字，后续如果相同名字的面包屑则不继续追加
                const arr: string[] = [];
                this.breadCrumbRoute.forEach((i) => {
                    arr.push(i.breadcrumbName as string);
                });
                if (!arr.includes(<string>data[0].breadcrumbName)) {
                    this.breadCrumbRoute.push(data[0]);
                }
            }
        },
    },
    persist: {
        enabled: true,
        strategies: [{ key: 'breadCrumbRoute', storage: sessionStorage, paths: ['breadCrumbRoute'] }],
    },
});

export default useLayoutStore;
