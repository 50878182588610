<template>
    <div class="pic_container set_center">
        <div v-if="props.itemData?.is_vip" class="image_element">
            <a-avatar
                @loadError="errorEvent"
                v-img-lazy="baseImageUrl + (props.type ? props.itemData?.local_images[0] : props.itemData?.local_thumbnail)"
                class="pic_element"
                :src="baseImageUrl + (props.type ? props.itemData?.local_images[0] : props.itemData?.local_thumbnail) || ''"
                alt=""
                @click="showDetail"
            />
        </div>
        <div v-else="props.itemData.is_vip" class="image_element blur-image">
            <a-avatar
                @loadError="errorEvent"
                v-img-lazy="baseImageUrl + (props.type ? props.itemData?.local_images[0] : props.itemData?.local_thumbnail)"
                class="pic_element_watch_unable"
                :src="baseImageUrl + (props.type ? props.itemData?.local_images[0] : props.itemData?.local_thumbnail) || ''"
                alt=""
            />
        </div>
        <div class="vip_watch" v-if="!props.itemData?.is_vip">
            <span @click="viewImage">
                <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <path d="M509.76 623.68A109.76 109.76 0 0 0 615.68 512l-111.36 111.36z"></path>
                    <path d="M960 512a787.2 787.2 0 0 0-158.4-184.64l-119.68 119.68a195.84 195.84 0 0 1 10.88 64 187.84 187.84 0 0 1-183.04 192 176.96 176.96 0 0 1-70.08-14.4l-91.2 91.2A392.96 392.96 0 0 0 512 816c267.52 0 440.64-290.24 448-302.72h-2.24zM385.92 651.52l55.04-55.04 153.6-153.6 55.68-55.68L750.4 288l120.32-120.32-45.44-46.4-131.84 131.84-32-14.08c-10.56-4.48-21.76-8.64-32-12.16A379.52 379.52 0 0 0 512 208C244.48 208 71.36 498.24 64 512h2.24H64a800 800 0 0 0 124.48 154.56l23.68 21.44c8 7.04 16.32 13.76 24.64 20.48l-115.52 116.8 45.44 45.44 123.2-123.2z m-38.4-52.48a188.8 188.8 0 0 1-12.8-32 186.24 186.24 0 0 1-7.04-38.08 174.08 174.08 0 0 1 0-18.24 187.84 187.84 0 0 1 183.04-192 136.96 136.96 0 0 1 25.92 2.24 151.36 151.36 0 0 1 35.84 9.28 156.8 156.8 0 0 1 30.4 14.72L544 403.2a97.6 97.6 0 0 0-33.6-6.08h-5.76a108.8 108.8 0 0 0-99.2 99.2 96 96 0 0 0 0 13.76 122.24 122.24 0 0 0 3.52 28.8l-59.84 59.84z"></path>
                </svg>
            </span>
        </div>
        <div class="operate_btn" v-else="!props.itemData.is_vip">
            <div class="collect" @click="collectCreative">
                <img v-show="props.itemData?.is_collected == 'No'" src="@/assets/images/layout/collect.png" alt="" />
                <img v-show="props.itemData?.is_collected == 'Yes'" src="@/assets/images/layout/collect_active.png" alt="" />
            </div>
        </div>
        <CreativeDetail ref="creativeModal"></CreativeDetail>
        <PayPoints ref="pointsModel"></PayPoints>
        <LoginModel ref="loginForm"></LoginModel>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { pubilcGenerationsListRes } from '@/types/creative';
import { collectItem } from '@/api/creative';
import { ApiWithoutRes } from '@/types/data';
import CreativeDetail from '@/views/creativeSquare/components/creativeDetail.vue';
import router from '@/router';
import { useUserStore } from '@/store';
import LoginModel from '@/views/home/loginModel.vue';
import PayPoints from '@/layout/modules/payPoints.vue';

type Props = {
    itemData: pubilcGenerationsListRes | null;
    type?: string;
};

const props = withDefaults(defineProps<Props>(), {
    itemData: null,
    type: '',
});

const baseImageUrl = `${import.meta.env.VITE_URL_IMAGEURL}/`;

const errorEvent = (e) => {
    console.log(e);
};

const emit = defineEmits(['getList']);
const isCollect = ref(props.itemData!.is_collected !== 'No');

const collectCreative = async () => {
    const res = (await collectItem({ collect_id: props.itemData!.id })) as ApiWithoutRes;
    if (!res.code) {
        if (props.itemData) {
            props.itemData.is_collected = res.data.is_collected;
        }
        isCollect.value = !isCollect.value;
        if (props.type) {
            emit('getList', props.itemData?.id);
        }
    }
};

const createPic = () => {
    if (!props.type) {
        router.push({ name: 'home', query: { local_thumbnail: props.itemData!.local_thumbnail } });
    } else {
        router.push({ name: 'home', query: { local_thumbnail: props.itemData!.local_images[0] } });
    }
};

const creativeModal = ref();
const showDetail = async () => {
    creativeModal.value.initData(props.itemData);
};

const pointsModel = ref();
const loginForm = ref();
const viewImage = () => {
    if (useUserStore().getToken) {
        pointsModel.value.initData();
    } else {
        loginFirst();
    }
};
const loginFirst = () => {
    loginForm.value.initData();
};
</script>

<style lang="less" scoped>
@keyframes move {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-54px);
    }
}

@keyframes fadeIn {
    0% {
        bottom: 60px;
    }
    100% {
        bottom: 16px;
    }
}

.pic_container {
    position: relative;
    width: 326px;
    height: 398px;
    background: #2a3846;
    border-radius: 8px;
    flex-direction: column;

    .image_element {
        width: 278px;
        height: 350px;
        border-radius: 8px;
        z-index: 3;
        background-color: #2e3845;

        .pic_element {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            cursor: pointer;
        }

        .pic_element_watch_unable {
            width: 100%;
            height: 100%;
            border-radius: 8px;
        }
    }

    .operate_btn {
        position: absolute;
        right: 38px;
        bottom: 38px;
        z-index: 100;
        justify-content: space-between;
        display: flex;

        img {
            width: 30px;
            height: 30px;
            margin-left: 6px;
        }

        .collect {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            overflow: hidden;
        }
    }


}
.blur-image {
    filter: blur(5px) brightness(0.5);
}
.vip_watch {
  position: absolute;
  z-index: 100;
  width: 35px;
  height: 35px;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
}
</style>
