<template>
    <a-spin :delay="300" :spinning="loadingStore.loadingState" size="large">
        <a-config-provider :locale="zhCN">
            <router-view />
        </a-config-provider>
    </a-spin>
</template>

<script lang="ts" setup>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import { useLoadingStore } from '@/store';
import 'dayjs/locale/zh-cn';

const loadingStore = useLoadingStore();
</script>

<style lang="less">
#app {
    width: 100%;
    height: 100%;
}

.ant-spin-nested-loading,
.ant-spin-container {
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.ant-spin {
    max-height: unset !important;
}
</style>
