<template>
    <div>
        <!--      :maskClosable="false"-->
        <h-modal v-model:visible="visible" :afterClose="cancel" :confirm-loading="confirmLoading" :closable="false" :footer="null">
            <div style="margin-left: 16px; margin-right: 16px">
                <div v-show="!registerStatus">
                    <!--正常登陆界面-->
                    <div class="login_type set_center">
                        <div :class="{ active: loginType === 1 }" @click="changeLoginType(1)">密码登录</div>
                        <div :class="{ active: loginType === 2 }" @click="changeLoginType(2)">验证码登录</div>
                    </div>
                    <a-form v-show="loginType === 1" ref="loginFormRef" :model="loginForm" :rules="loginRules" class="form" layout="vertical">
                        <a-form-item name="user_name">
                            <h-input v-model:value="loginForm.user_name" placeholder="请输入您的邮箱" />
                        </a-form-item>
                        <a-form-item name="password">
                            <a-input-password v-model:value="loginForm.password" placeholder="请输入密码" @keyup.enter="onAccountSubmit" />
                        </a-form-item>
                        <!--                        <a-form-item>-->
                        <!--                            <a-checkbox v-model:checked="isRememberPas">记住密码</a-checkbox>-->
                        <!--                        </a-form-item>-->
                        <a-form-item>
                            <HButton style="height: 40px" :loading="loading" block @click="onAccountSubmit">登录</HButton>
                        </a-form-item>
                    </a-form>

                    <a-form v-show="loginType === 2" ref="mobileFormRef" :model="mobileForm" :rules="mobileRules" class="form" layout="vertical">
                        <a-form-item name="email">
                            <h-input v-model:value="mobileForm.email" placeholder="请输入您的邮箱" />
                        </a-form-item>
                        <a-form-item name="code" class="code">
                            <h-input v-model:value="mobileForm.code" placeholder="请输入您的验证码" @keyup.enter="onMobileSubmit" />
                            <span class="getCode_button text_active" v-show="!codeStatus" @click="getMobileCode(mobileForm.email, 'Login')">获取验证码</span>
                            <span class="getCode_button" style="color: #a2a5a8; cursor: default" v-show="codeStatus">{{ deadCount + 's' }}</span>
                        </a-form-item>
                        <a-form-item>
                            <HButton style="height: 40px" :loading="loading" block @click="onMobileSubmit">登录</HButton>
                        </a-form-item>
                    </a-form>
                    <div class="register set_level_center" @click="registerUser(1)">立即注册</div>
                </div>

                <div class="register_form" v-show="registerStatus">
                    <div class="return_button set_vertical_center">
                        <img src="@/assets/images/layout/return_log.png" alt="" @click="registerUser(0)" />
                        <div style="margin-left: 12px; font-size: 20px">立即注册</div>
                    </div>
                    <a-form ref="registerFormRef" :model="registerForm" :rules="registerRules" class="form" layout="vertical">
                        <a-form-item name="email">
                            <h-input v-model:value="registerForm.email" placeholder="请输入您的邮箱" />
                        </a-form-item>
                        <a-form-item name="code" class="code">
                            <h-input v-model:value="registerForm.code" placeholder="请输入您的验证码" />
                            <span class="getCode_button text_active" @click="getMobileCode(registerForm.email, 'Register')" v-show="!codeStatus">获取验证码</span>
                            <span class="getCode_button" style="color: #a2a5a8; cursor: default" v-show="codeStatus">{{ deadCount + 's' }}</span>
                        </a-form-item>
                        <a-form-item name="password">
                            <a-input-password v-model:value="registerForm.password" placeholder="请输入新密码" />
                        </a-form-item>
                        <a-form-item name="passwordConfirmation">
                            <a-input-password v-model:value="registerForm.passwordConfirmation" placeholder="请再次输入新密码" />
                        </a-form-item>
                        <a-form-item>
                            <HButton style="height: 40px" :loading="loading" block @click="registerSubmit">注册</HButton>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
        </h-modal>
    </div>
</template>

<script setup lang="ts">
import { reactive, ref, watch } from 'vue';
import { message } from 'ant-design-vue/es';
import { ApiRes, loginFormConfig, loginResult, registerFormConfig } from '@/types/data';
import { codeLogin, pasLogin, registerUsers, sendCode } from '@/api/system';
import { useUserStore } from '@/store';

const registerRules = {
    email: [
        {
            required: true,
            message: '请输入邮箱',
            trigger: 'blur',
        },
    ],
    password: [
        {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
        },
    ],
    passwordConfirmation: [
        {
            required: true,
            message: '请再次输入密码',
            trigger: 'blur',
        },
    ],
    code: [
        {
            required: true,
            message: '请输入验证码',
            trigger: 'blur',
        },
    ],
};
const registerForm = ref<registerFormConfig>({
    email: '',
    password: '',
    passwordConfirmation: '',
    code: '',
});

const registerFormRef = ref();
const registerSubmit = () => {
    registerFormRef.value.validate().then(async () => {
        const params = { ...JSON.parse(JSON.stringify(registerForm.value)) };
        params.password_confirmation = params.passwordConfirmation;
        const res = (await registerUsers(params)) as ApiRes<loginResult>;
        if (!res.code) {
            message.success('注册成功');
            await useUserStore().getUserInfoData();
            cancel();
        }
    });
};

const registerStatus = ref(false);
const registerUser = (type) => {
    registerStatus.value = !!type;
    if (type) {
        // 立即注册 重置表单
        registerFormRef.value.resetFields();
    } else {
        // 返回正常登陆
    }
};

const deadCount = ref(300);
const countTimer = ref();
const codeStatus = ref(false);
const getMobileCode = async (phone, type) => {
    const params = {
        email: phone,
        type,
    };
    const res = (await sendCode(params)) as ApiRes<loginResult>;
    if (!res.code) {
        codeStatus.value = true;
        countTimer.value = setInterval(() => {
            deadCount.value -= 1;
            if (deadCount.value === 0) {
                clearInterval(countTimer.value);
                codeStatus.value = false;
            }
        }, 1000);
    }
};

const visible = ref<boolean>(false);
const loading = ref(false);
const confirmLoading = false;
const initData = () => {
    visible.value = true;
    useUserStore().setNeedLogin(false);
};

const cancel = () => {
    visible.value = false;
    clearInterval(countTimer.value);
    deadCount.value = 300;
    loginFormRef.value.resetFields();
    mobileFormRef.value.resetFields();
    registerFormRef.value.resetFields();
    loginType.value = 1;
};

const loginForm: loginFormConfig = reactive({
    user_name: '',
    password: '',
});
const loginRules = {
    user_name: [
        {
            required: true,
            message: '请输入账号',
            trigger: 'blur',
        },
    ],
    password: [
        {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
        },
    ],
};

const loginFormRef = ref();
const onAccountSubmit = () => {
    loginFormRef.value.validate().then(async () => {
        const params = { ...JSON.parse(JSON.stringify(loginForm)) };
        const res = (await pasLogin(params)) as ApiRes<loginResult>;
        if (!res.code) {
            loginSuccessCallback();
        }
    });
};

const mobileRules = {
    email: [
        {
            required: true,
            message: '请输入邮箱',
            trigger: 'blur',
        },
    ],
    code: [
        {
            required: true,
            message: '请输入验证码',
            trigger: 'blur',
        },
    ],
};
const mobileForm = ref<{
    email: string;
    code: string;
}>({
    email: '',
    code: '',
});

const loginSuccessCallback = async () => {
    message.success('登录成功');
    await useUserStore().getUserInfoData();
    cancel();
};

const mobileFormRef = ref();
const onMobileSubmit = () => {
    mobileFormRef.value.validate().then(async () => {
        const params = { ...JSON.parse(JSON.stringify(mobileForm.value)) };
        const res = (await codeLogin(params)) as ApiRes<loginResult>;
        if (!res.code) {
            loginSuccessCallback();
        }
    });
};

const loginType = ref(1);
const changeLoginType = (type) => {
    if (countTimer.value) {
        clearInterval(countTimer.value);
        deadCount.value = 300;
        codeStatus.value = false;
    }
    loginType.value = type;
    if (type === 1) {
        mobileFormRef.value.resetFields();
    } else {
        loginFormRef.value.resetFields();
    }
};

watch(
    () => useUserStore().getNeedLogin,
    (newVal) => {
        if (newVal) {
            initData();
        }
    }
);

defineExpose({
    initData,
});
</script>

<style lang="less" scoped>
.login_type {
    justify-content: space-around;
    margin: 16px 0 34px;

    .active {
        background: linear-gradient(134deg, #30e38d 0%, #1bffe1 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        border-bottom: 3px solid #30e38d;
    }

    div {
        font-size: 20px;
        font-weight: bold;
        font-family: '微软雅黑 Light';
        padding-bottom: 8px;
        cursor: pointer;

        &:hover {
            background: linear-gradient(134deg, #30e38d 0%, #1bffe1 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            border-bottom: 3px solid #30e38d;
        }
    }
}

.ant-checkbox-wrapper {
    color: #fff;

    :deep(.ant-checkbox-checked .ant-checkbox-inner) {
        color: #1c232d !important;
        background-color: #30e38d !important;
        border: none;
    }
}

:deep(.ant-checkbox-inner) {
    background: #1f2a38;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 50% !important;

    .ant-checkbox-wrapper:hover {
        border: 1px solid rgba(255, 255, 255, 0.2) !important;
    }
}

.code {
    position: relative;

    .getCode_button {
        cursor: pointer;
        position: absolute;
        right: 24px;
        top: 27%;
    }
}

.register {
    cursor: pointer;
    margin: 24px 0 16px;
    color: #a2a5a8;
}

.register_form {
    .return_button {
        margin-bottom: 32px;

        img {
            cursor: pointer;
        }
    }
}
</style>
