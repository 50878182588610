<template>
    <div class="head-container">
        <a-layout-header class="header">
            <div class="left">
                <img alt="" class="logo" src="@/assets/images/layout/mm-logo.png" @click="router.push({ name: 'home' })" />
                <div class="menu_type">
                    <div class="menu_info" :class="{ active: route.name === 'creativeSquare' }" @click="toPage(0)">
                        <!-- <div class="menu_info_icon ground"></div> -->
                        <div class="menu_info_text">首页</div>
                    </div>
                    <div class="menu_info" :class="{ active: route.name === 'myCreative' }" @click="toPage(1)">
                        <!-- <div class="menu_info_icon mybase"></div> -->
                        <div class="menu_info_text">我的美媚</div>
                    </div>
                </div>
            </div>
            <div class="right set_center">
                <div class="banner">
                    <a href="/create">
                        <img src="@/assets/images/create.png" alt="" />
                    </a>
                </div>

                <div class="appearance_count">
                    媚值: <span class="text_active">{{ useUserStore().getToken ? useUserStore().getUserInfo?.point : 0 }}</span>
                </div>
                <div class="appearance_button" @click="payThePoints"></div>
                <div class="item">
                    <div v-if="!useUserStore().getToken" class="login_icon set_center" @click="openLogin">登录</div>
                    <a-dropdown v-if="useUserStore().getToken" placement="bottom" overlayClassName="dropdownMenu">
                        <div class="user">
                            <a-avatar :src="useUserStore().getUserInfo?.avatar || ''" class="avatar"></a-avatar>
                        </div>
                        <template #overlay>
                            <a-menu>
                                <a-menu-item @click="openInfo">
                                    <setting-outlined style="margin-right: 5px" />
                                    <span>个人设置</span>
                                </a-menu-item>
                                <a-menu-item @click="outLogin">
                                    <poweroff-outlined style="margin-right: 5px" />
                                    <span>退出登录</span>
                                </a-menu-item>
                            </a-menu>
                        </template>
                    </a-dropdown>
                </div>
                <PayPoints ref="pointsModel"></PayPoints>
            </div>
        </a-layout-header>
        <LoginModel ref="loginForm"></LoginModel>
    </div>
</template>

<script lang="ts" setup>
import { PoweroffOutlined, SettingOutlined } from '@ant-design/icons-vue';
import { useRoute, useRouter } from 'vue-router';
import { ref } from 'vue';
import { message } from 'ant-design-vue/es';
import { useUserStore } from '@/store';
import LoginModel from '@/views/home/loginModel.vue';
import PayPoints from '@/layout/modules/payPoints.vue';

const router = useRouter();
const route = useRoute();

const outLogin = () => {
    useUserStore().clearToken();
};
const openInfo = () => {
    router.push({ name: 'setting' });
};
const loginForm = ref();
const openLogin = () => {
    loginForm.value.initData();
};
const pointsModel = ref();
const payThePoints = () => {
    if (useUserStore().getToken) {
        pointsModel.value.initData();
    } else {
        loginFirst();
    }
};

const loginFirst = () => {
    // message.info('请先登录');
    loginForm.value.initData();
};

const currentTag = ref();
const toPage = (type) => {
    currentTag.value = type;
    if (!type) {
        router.push({ name: 'home' });
    } else if (type) {
        if (useUserStore().getToken) {
            router.push({ name: 'my-creations' });
        } else {
            loginFirst();
        }
    }
};
// onMounted(() => {
//     if (!useUserStore().getToken) {
//         loginForm.value.initData();
//     }
// });
</script>

<style lang="less" scoped>
.head-container {
    background-color: #131a24;

    div {
        white-space: nowrap;
    }
}

.header {
    height: 80px;
    z-index: 10;
    width: 100%;
    padding: 0 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to right, rgba(23, 55, 53, 1), rgba(17, 43, 41, 0.28), rgba(27, 112, 84, 0.28), rgba(32, 152, 109, 0.15));
    color: #fff;
    font-size: 18px;

    .left {
        /*实现文字颜色从红到黄的线性渐变效果*/
        display: flex;
        align-items: center;
        position: relative;

        .top_left {
            position: absolute;
            left: -32px;
        }

        .top_right {
            position: fixed;
            right: 0px;
        }

        .logo {
            margin-right: 34px;
            cursor: pointer;
            z-index: 10;
        }

        .menu_type {
            font-size: 16px;
            display: flex;

            .menu_info {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                z-index: 10;

                &:first-child {
                    margin-right: 54px;
                }

                .menu_info_icon {
                    width: 24px;
                    height: 24px;
                }

                .ground {
                    margin-right: 8px;
                    background: url('@/assets/images/layout/square.png') no-repeat 0 0;
                }

                .mybase {
                    margin-right: 8px;
                    background: url('@/assets/images/layout/my.png') no-repeat 0 0;
                }

                .active,
                &:hover {
                    color: #30e38d;

                    .ground {
                        background: url('@/assets/images/layout/square_selected.png') no-repeat 0 0;
                    }

                    .mybase {
                        background: url('@/assets/images/layout/my_selected.png') no-repeat 0 0;
                    }
                }
            }

            .active {
                color: #30e38d;

                .ground {
                    background: url('@/assets/images/layout/square_selected.png') no-repeat 0 0;
                }

                .mybase {
                    background: url('@/assets/images/layout/my_selected.png') no-repeat 0 0;
                }
            }
        }

        .banner-left {
            text-align: center;
            color: #fff;
            width: 240px;
            height: 50px;
            line-height: 50px;
            z-index: 12;
            cursor: pointer;
            margin-right: 30px;
        }
    }

    .right {
        .banner {
            text-align: center;
            color: #fff;
            width: 299px;
            height: 50px;
            line-height: 50px;
            //background: #5e6772;
            z-index: 12;
            cursor: pointer;
        }

        .appearance_count {
            margin-left: 64px;
        }

        .appearance_button {
            width: 152px;
            height: 34px;
            margin: 0 48px 0 32px;
            cursor: pointer;
            z-index: 12;

            background: url('@/assets/images/layout/vip.png') no-repeat 0 0;
        }
    }

    .item {
        cursor: pointer;
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        transition: color 0.3s;

        .login_icon {
            width: 46px;
            height: 46px;
            border-radius: 50%;
            color: #30e38d;
            font-size: 14px;
            border: 1px solid #30e38d;
            cursor: pointer;
            z-index: 12;
        }

        :deep(.ant-avatar) {
            background: #2b7eff !important;
        }
    }

    .user {
        display: flex;
        align-items: center;

        .avatar {
            width: 46px;
            height: 46px;
        }
    }
}

.text_active {
    color: #fff;
}

:deep(.dropdownMenu) {
    :deep(.ant-dropdown-menu) {
        color: #d3d5d8 !important;
        background: #1c232d !important;
        border: 1px solid rgba(48, 227, 141, 0.1) !important;
    }
}
</style>
