<template>
    <div>
        <h-modal v-model:visible="visible" :needConfirm="wordList?.length ? true : false" title="我的词条" :confirm-loading="confirmLoading" @cancelEvent="cancel" @okEvent="submit" :footer="null">
            <div class="word_list">
                <div class="word_list_item set_vertical_center" @click="handleSelect(i)" :class="{ active: i.id === currentId }" v-for="i in wordList" :key="i.id">
                    <div class="text">{{ i.prompt }}</div>
                    <img src="@/assets/images/layout/delete.png" alt="" @click="deleteItem(i)" />
                </div>
                <div class="noData" v-show="!wordList?.length">
                    <a-empty :image="Empty.PRESENTED_IMAGE_SIMPLE" />
                </div>
            </div>
            <a-pagination
                v-if="maxPages > 1"
                size="small"
                style="color: #fff; text-align: right"
                :total="pagination.total"
                :show-total="pagination.showTotal"
                :pageSize="pagination.pageSize"
                :current="pagination.current"
                @change="handleChange"
            />
        </h-modal>
    </div>
</template>

<script setup lang="ts">
import { Empty, message, Modal } from 'ant-design-vue';
import { createVNode, ref } from 'vue';

import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { myPrompts, savePrompts } from '@/api/home';
import { ApiRes, ApiWithoutRes, pageResult } from '@/types/data';

const visible = ref<boolean>(false);
const confirmLoading = false;

type wordListRes = {
    created_at: string;
    prompt: string;
    id: number;
};
const pagination = ref({
    total: 0,
    showTotal: (total) => `总共 ${total} 条`,
    current: 1,
    pageSize: 6,
    defaultPageSize: 6,
});

const wordList = ref<wordListRes[]>();
const maxPages = ref();
const getMyWordList = async () => {
    const res = (await myPrompts({
        page: pagination.value.current,
        limit: pagination.value.pageSize,
    })) as ApiRes<pageResult<wordListRes>>;
    if (!res.code) {
        wordList.value = res.data.data;
        pagination.value.total = res.data.total;
        maxPages.value = res.data.pages;
    }
};

const handleChange = (current) => {
    pagination.value.current = current;
    getMyWordList();
};

const currentId = ref(null);
const currentWord = ref('');
const handleSelect = (data) => {
    currentId.value = data.id;
    currentWord.value = data.prompt;
};

const initData = () => {
    pagination.value.current = 1;
    getMyWordList();
    visible.value = true;
};

const cancel = () => {
    visible.value = false;
    currentId.value = null;
    currentWord.value = '';
};

const deleteItem = async (record) => {
    Modal.confirm({
        title: '确认删除',
        icon: createVNode(ExclamationCircleOutlined),
        content: '删除后无法恢复，确定删除吗？',
        async onOk() {
            const res = (await savePrompts({ id: record.id, prompt: record.prompt })) as ApiWithoutRes;
            if (!res.code) {
                message.success('删除成功');
                await getMyWordList();
            }
        },
    });
};

const emit = defineEmits(['editWordText']);
const submit = () => {
    if (!currentWord.value) return message.success('请先选择词条');
    emit('editWordText', currentWord.value);
    cancel();
};

defineExpose({
    initData,
});
</script>

<style lang="less" scoped>
.word_list {
    margin-bottom: 24px;

    .word_list_item {
        width: 100%;
        background: #14171e;
        padding: 14px 16px;
        justify-content: space-between;
        margin-bottom: 16px;
        border: 1px solid #14171e;
        cursor: pointer;

        .text {
            max-width: 380px;
        }

        &:hover {
            border: 1px solid #30e38d;
        }
    }

    .active {
        border: 1px solid #30e38d;
    }
}

:deep(.ant-pagination) {
    color: #fff !important;
    text-align: right !important;
}

:deep(.ant-pagination-item) a {
    color: #fff !important;
}

:deep(.ant-pagination-item) a:hover {
    color: #30e38d !important;
}

:deep(.ant-pagination-prev) svg {
    color: #fff !important;
}

:deep(.ant-pagination-prev) svg:hover {
    color: #30e38d !important;
}

:deep(.ant-pagination-disabled) {
    color: #fff !important;
}

:deep(.ant-pagination-next) svg {
    color: #fff !important;
}

:deep(.ant-pagination-next) svg:hover {
    color: #30e38d !important;
}

:deep(.ant-pagination-item-active) a {
    color: #30e38d !important;
}

:deep(.ant-pagination-item-active):hover {
    border-color: #30e38d;
}

:deep(.ant-pagination-item-active) {
    background-color: rgba(48, 227, 141, 0.05);
    border-color: #30e38d;
    color: #30e38d !important;
}

:deep(.ant-empty-description) {
    color: #fff;
}
</style>
