<template>
    <div class="toolBox" :style="{ height: props.height + 'px' }">
        <div class="toolBox_shadow">
            <div class="toolBox_bgc"></div>
            <div class="main_container">
                <div class="title set_vertical_center">
                    <div class="title_icon">
                        <span></span>
                        <span></span>
                    </div>
                    <div class="title_value">{{ props.title }}</div>
                </div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{ height?: number | string; title: string }>(), {
    height: 228,
    title: '123123',
});
</script>

<style lang="less" scoped>
.toolBox {
    width: 520px;
    position: relative;
    padding: 0px;
    background-image: linear-gradient(180deg, rgba(48, 227, 141, 0.3), rgba(48, 227, 141, 0.04), rgba(20, 255, 213, 0.3));
    border-radius: 12px;

    .toolBox_shadow {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        box-shadow: inset 0 4px 20px 0 rgba(14, 26, 33, 0.3);
        background: #151a24;
    }

    .toolBox_bgc {
        position: absolute;
        width: 100%;
        height: 69px;
        background: linear-gradient(180deg, rgba(48, 227, 141, 0.1) 0%, rgba(48, 227, 141, 0) 38%, rgba(48, 227, 141, 0) 100%);
        border-radius: 10px;
    }

    .main_container {
        padding: 16px;

        .title {
            margin-bottom: 16px;

            .title_icon {
                margin-right: 8px;
                padding-bottom: 4px;

                span {
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                }

                span:first-child {
                    background: #30e38d;
                }

                span:last-child {
                    background: #30e3d8;
                }
            }

            .title_value {
                font-weight: bold;
                font-size: 16px;
            }
        }
    }
}
</style>
